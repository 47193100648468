//import { getBearerToken } from 'lib/authentication/account-provider';
import { stringify } from 'querystring';
import { NonOkResponse } from './model/common-models';
import { sendElasticClientLogs } from 'src/opentelemetry/elastic-rum-config';
import { getSession } from 'next-auth/react';
import { getCookie } from 'cookies-next';

const getBaseUrl = (useGateway: boolean): string => {
  if (useGateway) {
    return process.env.NEXT_PUBLIC_COMMERCE_API_GATEWAY_BASE_URL ?? '';
  }
  return process.env.NEXT_PUBLIC_SALESFORCE_BASE_URL ?? '';
};

const getStore = (): string => {
  return process.env.NEXT_PUBLIC_SALESFORCE_STORE_NAME ?? '';
};

const baseRequest = async <TSuccess>(
  baseUrl: string,
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  path: string,
  body: Record<string, unknown> | null = {},
  queryParams: Record<string, string> | null = {},
  bearerToken: string
): Promise<TSuccess | NonOkResponse> => {
  //const bearerToken = await getBearerToken();

  const finalQueryParams: Record<string, string> = {
    webstoreName: getStore(),
    ...queryParams,
  };

  const finalBody: Record<string, string> = {
    webStoreName: getStore(),
    ...body,
  };

  const headers: HeadersInit = {};
  if (bearerToken) {
    headers.Authorization = `Bearer ${bearerToken}`;
  }

  const queryParamsString = stringify(finalQueryParams);
  const requestURL = `${baseUrl}${path}${
    queryParamsString.length > 0 ? `?${queryParamsString}` : ''
  }`;
  const response = await fetch(requestURL, {
    method: method,
    headers: headers,
    body: method != 'GET' ? JSON.stringify(finalBody) : null,
  });

  if (!response.ok) {
    let data;
    try {
      const responseData = await response.text();
      data = JSON.parse(responseData);
    } catch {
      data = null;
    }
    const nonOkResponse: NonOkResponse = {
      descriminator: '',
      status: response.status,
      data: data,
    };

    let userEmail;
    userEmail = getCookie('user-email');
    if (!userEmail) {
      const session = await getSession();
      userEmail = session?.user?.email;
    }

    const errorMessage = `Commerce Request Error [${path}]:
    ${method}: ${baseUrl}${path};
    ${
      method != 'GET' ? 'Body: ' + JSON.stringify(finalBody) : 'Query params: ' + queryParamsString
    };
    Response Code: ${response.status};
    Response data: ${JSON.stringify(data)};
    User: ${userEmail};
    Page: ${window?.location.href};
    TimeStamp UTC: ${new Date().toISOString()}`;

    sendElasticClientLogs([errorMessage]);

    return nonOkResponse;
  }

  const responseData = await response.text();

  try {
    const data = JSON.parse(responseData);
    const result = data as TSuccess;
    return result;
  } catch {
    //may need to change later
    return responseData as unknown as TSuccess;
  }
};

export const commonRequest = async <TSuccess>(
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  path: string,
  body: Record<string, unknown> | null = {},
  queryParams: Record<string, string> | null = {},
  bearerToken: string
): Promise<TSuccess | NonOkResponse> => {
  return await baseRequest<TSuccess>(
    getBaseUrl(false),
    method,
    path,
    body,
    queryParams,
    bearerToken
  );
};

export const commonGatewayRequest = async <TSuccess>(
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  path: string,
  body: Record<string, unknown> | null = {},
  queryParams: Record<string, string> | null = {},
  bearerToken: string
): Promise<TSuccess | NonOkResponse> => {
  return await baseRequest<TSuccess>(
    getBaseUrl(true),
    method,
    path,
    body,
    queryParams,
    bearerToken
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const isNonOkResponse = (object: any): object is NonOkResponse => {
  if (typeof object === 'string' || object instanceof String) {
    return false;
  }

  return 'descriminator' in object;
};
