import { useI18n } from 'next-localization';
import MarketplaceDropDown, {
  DropdownMenuProps,
} from '../../dropdown/marketplace/MarketplaceDropDown';
type BaseProps = {
  label: string;
  isRequired: boolean;
  toShow?: boolean;
  className?: string;
  children: JSX.Element;
};

type InputItemProps = {
  label: string;
  value: string | undefined;
  isRequired: boolean;
  toShow?: boolean;
  action: React.Dispatch<React.SetStateAction<string>>;
  requiredValidationMessage?: string;
  triggerErrorState?: boolean;
};

type PhoneItemProps = {
  label: string;
  phoneNumber: string | undefined;
  isRequired: boolean;
  action: React.Dispatch<React.SetStateAction<string>>;
  toShow?: boolean;
  requiredValidationMessage?: string;
  triggerErrorState?: boolean;
};

type DropDownItemProps = DropdownMenuProps & {
  label: string;
  isRequired: boolean;
  toShow?: boolean;
};

const ItemBase = (props: BaseProps): JSX.Element => {
  const { label, isRequired, className, children, toShow = true } = props;
  const { t } = useI18n();

  return toShow ? (
    <div className="my-2 flex flex-col sm:flex-row items-center">
      <div className="w-full sm:w-1/2">
        {t(label)}
        {isRequired && <span className="text-danger">*</span>}
      </div>
      <div className={`w-full sm:w-1/2 border-b border-grayishblue ${className ?? ''}`}>
        {children}
      </div>
    </div>
  ) : (
    <></>
  );
};

export const InputItem = (props: InputItemProps): JSX.Element => {
  const { label, value, isRequired, action, toShow, triggerErrorState, requiredValidationMessage } =
    props;

  return (
    <ItemBase label={label} isRequired={isRequired} toShow={toShow}>
      <>
        <input
          className="bg-mp-background-darker w-full py-1 pl-3 focus:outline-none focus-visible:outline-none"
          type="text"
          value={value}
          required={isRequired}
          onChange={(e): void => {
            action(e.currentTarget.value);
          }}
        />
        {isRequired && triggerErrorState && (
          <p className="mt-1 text-xs text-danger">{requiredValidationMessage || ''}</p>
        )}
      </>
    </ItemBase>
  );
};

export const PhoneItem = (props: PhoneItemProps): JSX.Element => {
  const {
    label,
    isRequired,
    phoneNumber,
    triggerErrorState,
    requiredValidationMessage,
    action,
    toShow,
  } = props;

  return (
    <ItemBase label={label} isRequired={isRequired} toShow={toShow} className="">
      <>
        <input
          className={`w-full py-1 pl-3 bg-mp-background-darker focus:outline-none focus-visible:outline-none`}
          type="text"
          value={phoneNumber}
          required={isRequired}
          onChange={(e): void => {
            action(e.currentTarget.value);
          }}
          minLength={7}
          pattern="^[0-9]*$"
        />
        {isRequired && triggerErrorState && (
          <p className="mt-1 text-xs text-danger">{requiredValidationMessage || ''}</p>
        )}
      </>
    </ItemBase>
  );
};

export const DropDownItem = (props: DropDownItemProps): JSX.Element => {
  const {
    label,
    requiredValidationMessage,
    isRequired,
    defaultValue,
    options,
    passValueToParentFunction,
    triggerErrorState,
    toShow,
  } = props;

  return (
    <ItemBase label={label} isRequired={isRequired} toShow={toShow}>
      <MarketplaceDropDown
        options={options}
        passValueToParentFunction={passValueToParentFunction}
        required={isRequired}
        requiredValidationMessage={requiredValidationMessage}
        triggerErrorState={triggerErrorState}
        className="text-mp-txt-neutralLight"
        defaultValue={defaultValue}
      />
    </ItemBase>
  );
};

export default DropDownItem;
