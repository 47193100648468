import React, { useState } from 'react';
import { TabsProps } from './tabbed-container-props';
import Tab from './Tab';
import RenderTabbedPlaceholder from './RenderTabbedPlaceholder';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';

const MobileTabs = (props: TabsProps): JSX.Element => {
  const context = useSitecoreContext();
  const { tabs, placeholderRendering } = props;
  const isEditorContextActive = context?.sitecoreContext?.pageEditing;
  const [activeTabsArray, setActiveTabsArray] = useState(tabs.map(() => false));
  const [activeTab, setActiveTab] = useState(0);

  const tabSelectionHandler = (index: number): void => {
    const arrayCopy = [...activeTabsArray];
    activeTabsArray[index] ? (arrayCopy[index] = false) : (arrayCopy[index] = true);
    setActiveTabsArray(arrayCopy);
    activeTab == index ? setActiveTab(-1) : setActiveTab(index);
  };

  if (isEditorContextActive) {
    return <>Tests</>;
  }

  return (
    <div className="visible xl:hidden mx-6 mt-6 mb-10">
      <div className="z-10 w-full">
        <ul className="flex flex-col" aria-labelledby="mobileTabs">
          {tabs &&
            tabs.map((tab, index) => (
              <React.Fragment key={`tab_${index}`}>
                <Tab
                  key={`tab_${index}`}
                  source="mobile"
                  tab={tab}
                  active={activeTab}
                  index={index}
                  onTabSelection={tabSelectionHandler}
                  className={`border-b ${
                    activeTabsArray[index] ? 'border-primary' : 'border-neutral-light'
                  }`}
                />
                <RenderTabbedPlaceholder
                  name={`jss-tab-contents`}
                  rendering={placeholderRendering}
                  activeTab={activeTab}
                  mobileIndex={index}
                />
              </React.Fragment>
            ))}
        </ul>
        {tabs.length == 0 && (
          <RenderTabbedPlaceholder
            name="jss-tab-contents"
            rendering={placeholderRendering}
            activeTab={activeTab}
            mobileIndex={activeTab}
          />
        )}
      </div>
    </div>
  );
};

export default MobileTabs;
