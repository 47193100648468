import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import Script from 'next/script';
import React, { useEffect, useMemo } from 'react';
import {
  completeFastSpringCheckoutSession,
  FastSpringCallbackData,
  setFastSpringEmbeddedStoreStatus,
  setFastSpringTaxData,
} from 'components/checkout/checkout-slice';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { getBearerToken } from 'lib/authentication/account-provider';
import { sendElasticClientLogs } from 'src/opentelemetry/elastic-rum-config';
import AlertBox from 'components/alert/AlertBox';
import {
  AnalyticsProductItem,
  pushAddPurchaseEvent,
  pushCoveoPurchase,
} from 'lib/google-analytics/commerce';
import { useCurrencyConversionRates } from 'lib/commerce/webstore/webstore-hooks';
import { CurrencyData } from 'lib/commerce/webstore/model/webstore-models';
import { FastSpringCheckoutCompleteCallbackData } from '../checkout-models';

type PaymentCollectionProps = {
  orderConfirmationLink?: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let window: any;

const FastSpring = (props: PaymentCollectionProps): JSX.Element => {
  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);
  const [error, setError] = React.useState<string | null>(null);
  const [scriptLoaded, setScriptLoaded] = React.useState(false);

  const [dataRecivedWebhookData, setDataRecivedWebhookData] =
    React.useState<FastSpringCheckoutCompleteCallbackData | null>(null);

  const router = useRouter();
  const dispatch = useAppDispatch();

  const email = session?.user?.email;

  const fastSpringStoreBuilderKeys = useAppSelector(
    (state) => state.checkout.fastSpringStoreBuilderKeys
  );
  const salesforceOrderId = useAppSelector((state) => state.checkout.salesforceOrderId);
  const redirectUrlBase = props.orderConfirmationLink ?? `/order`;

  const currencyConversionRates = useCurrencyConversionRates();
  const customerInfo = useAppSelector((state) => state?.customer?.customerData);
  const cartItemData = useAppSelector((state) => state.cart.cartItemsData);
  const currency = cartItemData?.cartSummary.currencyIsoCode as string;
  const couponCode = cartItemData?.cartSummary?.coupons?.[0]?.couponCode;
  const cartValue = cartItemData?.cartSummary?.grandTotalAmount.toString() as string;
  const itemsArray = cartItemData?.cartItems.map((item, index) => {
    const cartItem = item.cartItem;
    return {
      id: cartItem.productId,
      sku: cartItem.productDetails.sku,
      name: cartItem.name,
      price: cartItem.unitAdjustedPrice,
      discount: (parseFloat(cartItem.listPrice) - parseFloat(cartItem.salesPrice)).toFixed(2),
      variant: cartItem.productDetails.fields.XC_SubscriptionType__c,
      brand: cartItem.productDetails.fields.VendorName__c,
      index: index.toString(),
      quantity: cartItem.quantity,
      transactionType: cartItem.transactionType,
      itemCategory: cartItem.productCategoryPaths.primary?.at(-1)?.name,
      itemCategory2: cartItem.productCategoryPaths.path1?.at(-1)?.name,
      itemCategory3: cartItem.productCategoryPaths.path2?.at(-1)?.name,
      itemCategory4: cartItem.productCategoryPaths.path3?.at(-1)?.name,
      productSku: cartItem.productDetails.sku,
    };
  });

  const coveoItemsArray = cartItemData?.cartItems.map((item) => {
    const cartItem = item.cartItem;
    return {
      product: {
        id: cartItem.productId,
        price: Number(cartItem.unitAdjustedPrice),
        quantity: Number(cartItem.quantity),
        productName: cartItem.name,
        category: cartItem.productCategoryPaths.primary?.at(-1)?.name,
      },
    };
  });

  useEffect(() => {
    if (fastSpringStoreBuilderKeys && scriptLoaded) {
      const getElasticErrorMsg = ({
        responseCode = 'n/a',
        responseMessage,
      }: {
        responseCode?: number | string;
        responseMessage: string;
      }): string => {
        return `Fast Spring Request Error:
          Payload: ${fastSpringStoreBuilderKeys?.plainPayload};
          SecurePaylod: ${fastSpringStoreBuilderKeys?.securePayload};
          secureKey: ${fastSpringStoreBuilderKeys?.secureKey};
          accessKey: ${process.env.NEXT_PUBLIC_FASTSPRING_ACCESS_KEY};
          Response Code: ${responseCode};
          Response data: ${responseMessage};
          User: ${email};
          Page: ${window?.location.href};
          TimeStamp UTC: ${new Date().toISOString()}`;
      };
      window.dataValidationCallback = (error): void => {
        error.forEach((field) => {
          if (!field.error) return;
          sendElasticClientLogs([getElasticErrorMsg({ responseMessage: field.error['display'] })]);
          setError(field.error['display']);
        });
      };
      window.dataErrorCallback = (code, errorString): void => {
        setError('An error occurred while processing your payment. Please try again.');
        sendElasticClientLogs([
          getElasticErrorMsg({
            responseCode: code,
            responseMessage: errorString,
          }),
        ]);
      };

      if (window.fastspring && window.fastspring.builder) {
        setTimeout(() => {
          window.dataCallback = (data: FastSpringCallbackData): void => {
            const fsGroups = data?.groups;
            const areAllGroupsItemsSelected = fsGroups.some((group) =>
              group.items.some((item) => item.selected)
            );
            if (fsGroups.length === 0 || !areAllGroupsItemsSelected) {
              setError('An error occurred while processing your payment. Please try again.');
              sendElasticClientLogs([
                getElasticErrorMsg({
                  responseMessage: 'Respons groups are empty or nothing is selected',
                }),
              ]);
            }
            dispatch(setFastSpringTaxData(data));
            dispatch(setFastSpringEmbeddedStoreStatus('succeeded'));
          };
          window.fastspring.builder.secure(
            fastSpringStoreBuilderKeys.securePayload,
            fastSpringStoreBuilderKeys.secureKey
          );
        }, 250);
      }
    }
  }, [dispatch, email, fastSpringStoreBuilderKeys, scriptLoaded]);

  useEffect(() => {
    if (salesforceOrderId && dataRecivedWebhookData && currencyConversionRates) {
      pushAddPurchaseEvent(
        salesforceOrderId,
        dataRecivedWebhookData,
        currency,
        currencyConversionRates?.rates[currency] as CurrencyData,
        cartValue,
        itemsArray as unknown as Array<AnalyticsProductItem>,
        couponCode,
        (customerInfo?.contactId as string) || ''
      );

      pushCoveoPurchase(
        coveoItemsArray!,
        salesforceOrderId,
        'Avid Webstore',
        Number(cartValue),
        dataRecivedWebhookData.tax,
        0,
        (customerInfo?.contactId as string) || '',
        '',
        currency
      );

      const redirectUrl = `${redirectUrlBase}?id=${salesforceOrderId}`;
      router.push(redirectUrl);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [redirectUrlBase, router, salesforceOrderId, dataRecivedWebhookData, currencyConversionRates]);

  return (
    <div>
      {!process.env.NEXT_PUBLIC_FASTSPRING_ACCESS_KEY && (
        <p>Error: FastSpring access key environment variable not set</p>
      )}
      {!process.env.NEXT_PUBLIC_FASTSPRING_EMBEDDED_STOREFRONT && (
        <p>Error: FastSpring storefront environment variable not set</p>
      )}
      <Script
        id="fsc-api"
        src="https://sbl.onfastspring.com/sbl/1.0.1/fastspring-builder.min.js"
        type="text/javascript"
        data-storefront={process.env.NEXT_PUBLIC_FASTSPRING_EMBEDDED_STOREFRONT ?? ''}
        data-access-key={process.env.NEXT_PUBLIC_FASTSPRING_ACCESS_KEY ?? ''}
        data-popup-webhook-received="dataPopupWebhookReceived"
        data-data-callback="dataCallback"
        data-validation-callback="dataValidationCallback"
        data-error-callback="dataErrorCallback"
        onReady={(): void => {
          setScriptLoaded(true);
          window.dataPopupWebhookReceived = (
            data: FastSpringCheckoutCompleteCallbackData
          ): void => {
            setDataRecivedWebhookData(data);

            dispatch(
              completeFastSpringCheckoutSession({ store: 'Webstore', bearerToken: bearerToken })
            );
          };
          // window.dataCallback = (data: FastSpringCallbackData): void => {
          //   dispatch(setFastSpringTaxData(data));
          //   dispatch(setFastSpringEmbeddedStoreStatus('succeeded'));
          // };
        }}
      />
      <div className={`${error ? '' : 'hidden'} mb-4`}>
        <AlertBox
          containerClass="bg-warning-light border-warning text-warning [&>div>.dismisable-icon]:text-almost-black p-4 flex w-fit justify-center justify-self-center items-baseline md:items-center border rounded space-x-2 col-span-12 lg:col-span-8 lg:col-start-3"
          alertMessage={error ?? ''}
          alertType="warning"
          isDismissible={false}
          showAlertBox={true}
          displayIcon={true}
        />
      </div>
      <div id="fsc-embedded-checkout-container"></div>
    </div>
  );
};

export default FastSpring;
