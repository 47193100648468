import Script from 'next/script';

const coveoApiKey =
  process.env.COVEO_API_KEY && process.env.COVEO_API_KEY.length > 0
    ? process.env.COVEO_API_KEY
    : '';

const organizationId =
  process.env.COVEO_ORGANIZATION && process.env.COVEO_ORGANIZATION.length > 0
    ? process.env.COVEO_ORGANIZATION
    : '';

const analyticsEndPointAddress =
  process.env.COVEO_ANALYTICS_ENDPOINT && process.env.COVEO_ANALYTICS_ENDPOINT.length > 0
    ? process.env.COVEO_ANALYTICS_ENDPOINT
    : '';
const analyticsEndpoint = 'https://' + organizationId + analyticsEndPointAddress;

const CoveoScripts = (): JSX.Element => {
  return (
    <>
      <Script
        type="text/javascript"
        id="coveoua-script"
        key="coveoua-script"
        dangerouslySetInnerHTML={{
          __html: `(function(c, o, v, e, O, u, a) { a = 'coveoua';
                  c[a] = c[a] || function() {
                      (c[a].q = c[a].q || []).push(arguments)
                  };
                  c[a].t = Date.now();
                  u = o.createElement(v);
                  u.async = 1;
                  u.src = e;
                  O = o.getElementsByTagName(v)[0];
                  O.parentNode.insertBefore(u, O)
})(window, document, 'script', 'https://static.cloud.coveo.com/coveo.analytics.js/2/coveoua.js')`,
        }}
      />
      <Script
        id="coveoanalytics"
        key="coveoanalytics"
        dangerouslySetInnerHTML={{
          __html: `coveoua("init", "${coveoApiKey}", "${analyticsEndpoint}");
                        window.coveoAddProduct = function (skuToAdd, productName, price, currency, category){                                             
                            coveoua('set', 'currencyCode', currency);
                            coveoua('ec:addProduct', {
                                    'id': skuToAdd,
                                    'name': productName,                                    
                                    'price': price,
                                    'category': category,
                                    'quantity': 1                                    
                                });
                                coveoua('ec:setAction', 'add');
                                coveoua('send', 'event');
                        };
                        window.coveoViewProduct = function (skuToAdd, productName, price, currency, category){                                                   
                            coveoua('set', 'currencyCode', currency);
                            coveoua('ec:addProduct', {
                                    'id': skuToAdd,
                                    'name': productName,                                    
                                    'price': price,
                                    'category': category,
                                    'quantity': 1                                    
                                });
                                coveoua('ec:setAction', 'detail');
                                coveoua('send', 'event');
                        };
                        window.coveoRemoveProduct = function (skuToRemove, productName, price, currency, productCategory, quantity){                                                      
                            coveoua('set', 'currencyCode', currency);
                            coveoua('ec:addProduct', {
                                    'id': skuToRemove,
                                    'name': productName,
                                    'price': price,
                                    'category':productCategory,
                                    'quantity': quantity,
                                });
                                coveoua('ec:setAction', 'remove');
                                coveoua('send', 'event');
                        };
                        window.coveoSendPurchase = function (orderItems, orderId, affiliation, revenue, tax, shipping, clientId, siteContext, currentCurrency){                                                        
                            coveoua('set', 'currencyCode', currentCurrency);
                            for (let i = 0; i < orderItems.length; i++) {
                                var entry = orderItems[i];
                                var product = entry.product;
                                coveoua('ec:addProduct', {
                                        'id': product.id,
                                        'name': product.productName,
                                        'price': product.price,
                                        'category': product.category,                                        
                                        'quantity': product.quantity,
                                    });
                            }
                            coveoua('ec:setAction', 'purchase', {
	                            'id': orderId,
	                            'affiliation': affiliation,
	                            'revenue': revenue,
	                            'tax': tax,
	                            'shipping': shipping,
                                'client_id': clientId,
                                'context_website': siteContext,
                                'website': siteContext
                            });
                            coveoua('send', 'event');
                        };
                      `,
        }}
      />

      <Script
        id="coveoPaginationData"
        key="coveoPaginationData"
        dangerouslySetInnerHTML={{
          __html: `          
          (async () => {            
            await customElements.whenDefined('atomic-search-interface');
            var searchInterface = document.querySelector('atomic-search-interface');
            const currentPath = window.location.pathname;
            const queryString = window.location.search;
            const params = new URLSearchParams(queryString); 
            const paramValue = params.get('firstResult');
            var amountPerPage = 0;
            
            document.addEventListener('DOMContentLoaded', () => {
              // Wait for the Atomic component to be available in DOM
              waitForAtomicComponent((searchInterface) => {
                if (searchInterface) 
                {
                  //Verify if interface is a result listing
                  const isListing = document.querySelector('.listing-interface');
                  if(isListing)
                  { 
                    updateCanonicalURL();            
                    updateInterface();                    
                  }                  
                } 
                else 
                {
                  console.error('Error: Atomic component not found.');
                }
              });
            });
          
            function updateInterface() {              
              
              if(!searchInterface)
              {
                console.error('No interface in update attempt');                 
                return;
              }
          
              if(searchInterface)
              { 
                const resultListElement = document.querySelector("atomic-result-list");
                const paginationElement = document.querySelector("atomic-pager");
          
                if (!paginationElement || !resultListElement) {                  
                  // If the pagination/result list element is not found, recheck after a delay
                  setTimeout(updateInterface, 1000); // Check again in 1 second
                  return;
                }
              
                // Open the pagination shadow DOM
                const shadowRoot = paginationElement.shadowRoot;              
                if (shadowRoot) {  
                  resultListElement.style.display = 'none';    
                  paginationElement.style.display = 'none';
                  const resultListShadowRoot = resultListElement.shadowRoot;
                  if(resultListShadowRoot)
                  {
                    if(resultListShadowRoot.querySelectorAll('atomic-result').length <= 0)
                    {
                      setTimeout(updateInterface, 1000); // Check again in 1 second
                      return;
                    }
                    amountPerPage = resultListShadowRoot.querySelectorAll('atomic-result').length;
                  }
                  if(amountPerPage > 0)
                  {
                    const paginationItems = shadowRoot.querySelectorAll(".btn-page");                      
              
                    if (paginationItems.length > 0) 
                    {
                      // Pagination items are available in the shadow DOM                    
                      //const pagerAmount = paginationItems.length;
                      
                      checkForValidURL();
                      updateCrawlableSet();                      
                                  
                      resultListElement.style.display = 'block';    
                      paginationElement.style.display = 'block';
                    }
                    else
                    {
                      // If the pagination/result list element is not found, recheck after a delay
                      setTimeout(updateInterface, 1000); // Check again in 1 second
                      return;
                    }
                  } 
                }
              }
            } 
            function checkForValidURL()
            { 
              setTimeout(() => 
              {              
                const paginationElement = document.querySelector("atomic-pager");
                
                if (!paginationElement) {
                  // If the pagination element is not found, recheck after a delay
                  setTimeout(checkForValidURL, 1000); // Check again in 1 second
                  return;
                }
                const shadowRoot = paginationElement.shadowRoot;              
            
                const paginationItems = shadowRoot.querySelectorAll(".btn-page");            
                paginationItems.forEach((item) => {
                  const anchorTag = item.querySelector('a');
                  if (!anchorTag) { 
                  const pgN = item.value;                   
                  const resultAmount = (pgN*amountPerPage) - amountPerPage;                    
            
                  // update link
                  item.innerHTML = "<a href='"+currentPath+"?firstResult=" + resultAmount +"'></a>";
                  } 
                });
              },1000); 
            }
            //Function to update the result list displayed for SEO crawler
            function updateCrawlableSet()
            {
              if(paramValue != null)
              {   
                const resultListElement = document.querySelector("atomic-result-list");
                const paginationElement = document.querySelector("atomic-pager");  
                const shadowRoot = paginationElement.shadowRoot; 
                const paginationItems = shadowRoot.querySelectorAll(".btn-page");
                const lastPaginationItem = paginationItems[paginationItems.length - 1];
                const maxValue = lastPaginationItem.value;            
                const maxFirstResultAmount = (maxValue*amountPerPage) - 10;            
                
                if(maxFirstResultAmount < paramValue)
                {            
                  const nextBtn = shadowRoot.querySelector('button[part="next-button"]');                            
                  nextBtn.click();
                  setTimeout(updateCrawlableSet, 10); // Check again
                  return;
                }
                else
                {
                  checkForValidURL();
                  // Check if param value matches the pagination item
                  paginationItems.forEach((item) => {                              
                    const pgN = item.value; 
                    const resultAmount = (pgN*amountPerPage) - amountPerPage; 
                    if(resultAmount == paramValue)
                    {
                      item.click();
                      checkForValidURL();                      
                      return;                                
                    }
                  });                     
                }                      
              }
            } 
          
            // Function to wait for the Atomic component to be available
            function waitForAtomicComponent(callback) 
            {
              const checkForComponent = () => {
                const atomicComponentExists = checkForAtomicComponent();
                if (atomicComponentExists) {
                  searchInterface = getAtomicComponent();
                  if (searchInterface) {
                    callback(searchInterface);
                    return;
                  }
                }
                setTimeout(checkForComponent, 500); 
              };          
              // Start checking for the component
              checkForComponent();
            }
          
            // Function to check for the presence of the Atomic component or necessary elements
            function checkForAtomicComponent() {              
              searchInterface = document.querySelector('atomic-search-interface');
          
              // Check if the Atomic component exists
              if (searchInterface) {
                return true; // Return true if the component is found
              } else {             
                return false; // Return false if the component is not found
              }
            }
            function getAtomicComponent() {              
              return searchInterface;
            }	
            function updateCanonicalURL()
            {
              const canonicalLink = document.querySelector('link[rel="canonical"]');
              if (canonicalLink) {
                const existingParams = window.location.search;
                const canonicalHref = canonicalLink.href;

                // Append existing query string parameters to the canonical URL
                const updatedCanonicalHref = canonicalHref + existingParams;
                canonicalLink.href = updatedCanonicalHref;
              } 
            }	  
          })(); 
          `,
        }}
      />
    </>
  );
};

export default CoveoScripts;
