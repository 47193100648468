import { Field, Item, Placeholder, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { useEffect } from 'react';

type ColumnLayoutProps = Item & {
  fields: {
    ratio: Field<string>;
  };
};

type TwoColumnHorizontalContainerProps = ComponentProps & {
  fields: {
    layout: ColumnLayoutProps;
  };
};

const TwoColumnHorizontalContainer = (props: TwoColumnHorizontalContainerProps): JSX.Element => {
  const ratio: string = props.fields?.layout?.fields?.ratio.value;
  let cssClasses = ratio.split('|');
  if (cssClasses.length !== 2) {
    cssClasses = ['1', '1'];
  }
  const gridClasses = {
    '2column': 'lg:grid-cols-2',
    '3column': 'lg:grid-cols-3',
    '1': 'col-span-1',
    '2': 'col-span-2',
  };
  const totalColumns = cssClasses.reduce((acc, a) => parseInt(a) + acc, 0).toString() + 'column';
  const gridCssClass = gridClasses[totalColumns as '2column' | '3column'];
  const leftCssClass = gridClasses[cssClasses[0] as '1' | '2'];
  const rightCssClass = gridClasses[cssClasses[1] as '1' | '2'];

  useEffect(() => {
    const rightContainer = document.querySelector('.right');
    const leftContainer = document.querySelector('.left');
    const image = document.querySelector('.nextMedia');
    if (rightContainer?.contains(image) && leftContainer) {
      rightContainer.classList.add('order-first', 'pt-0', 'lg:order-last');
      rightContainer.classList.remove('pt-6');
      leftContainer.classList.add('pt-6', 'lg:pt-0');
    }
  }, []);

  return (
    <div className="flex justify-center px-6 lg:px-8 mb-20">
      <div className={`max-w-content container grid grid-cols-1 ${gridCssClass}  lg:gap-x-8`}>
        <div className={`left ${leftCssClass} [&>.nextMedia]:p-0 [&>.nextMedia]:m-0`}>
          <Placeholder name="jss-left" rendering={props.rendering} />
        </div>
        <div
          className={`right ${rightCssClass} pt-6 lg:pt-0 [&>.nextMedia]:p-0 [&>.nextMedia]:m-0`}
        >
          <Placeholder name="jss-right" rendering={props.rendering} />
        </div>
      </div>
    </div>
  );
};

export default withDatasourceCheck()<TwoColumnHorizontalContainerProps>(
  TwoColumnHorizontalContainer
);
