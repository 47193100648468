import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { Placeholder, LayoutServiceData, Field } from '@sitecore-jss/sitecore-jss-nextjs';
import MetaData from 'components/foundation/non-sitecore/meta-data/MetaData';
import { useI18n } from 'next-localization';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { navigationActions } from 'lib/navigation/navigation-slice';
import { isEmpty } from 'lib/utils/string-format';
import TagManager from 'react-gtm-module';
import {
  gtWalshiemAvidRegular,
  gtWalshiemAvidLight,
  gtWalshiemAvidMedium,
  gtWalshiemAvidBold,
  gtWalshiemAvidRegularOblique,
} from 'lib/fonts/font-loader';
import { pushCoveoSearchEvent } from 'lib/google-analytics/coveo-site-search';
import CoveoSearchEngineContext from 'lib/coveo/coveo-context';
import { getLanguageName } from 'lib/navigation/navigation-utils';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { apmRUMInit } from 'src/opentelemetry/elastic-rum-config';
import { useCartData } from 'components/cart/cart-hooks';

interface DefaultLayoutProps {
  layoutData: LayoutServiceData;
}

const DefaultLayout = ({ layoutData }: DefaultLayoutProps): JSX.Element => {
  const editorActive = useSitecoreContext()?.sitecoreContext?.pageEditing;

  const { route } = layoutData.sitecore;
  const { locale, t } = useI18n();
  const lang = locale();
  const allContentLabel = t('All Content');
  const dispatch = useAppDispatch();
  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode);
  const cart = useCartData();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [searchEngine, setSearchEngine] = useState<any>(null);

  const gaTrackingId = process.env.NEXT_PUBLIC_GA_TRACKING_ID || '';
  const coveoQuery = useAppSelector((state) => state.coveoQuery?.query);
  const searchHub = useAppSelector((state) => state.coveoQuery?.searchHub);

  useEffect(() => {
    if (!isEmpty(gaTrackingId) && !editorActive) {
      TagManager.initialize({ gtmId: gaTrackingId });
    }
  }, [editorActive, gaTrackingId]);

  useEffect(() => {
    apmRUMInit();
  });

  const organizationId =
    process.env.COVEO_ORGANIZATION && process.env.COVEO_ORGANIZATION.length > 0
      ? process.env.COVEO_ORGANIZATION
      : '';

  useEffect(() => {
    if (currencyIsoCode == null || cart == null) return;

    const ignoreLangTabs: string[] = ['Videos'];

    dispatch(navigationActions.changeLanguage(lang));

    const initializeSearchEngine = async (): Promise<void> => {
      const coveo = await import('@coveo/atomic-react');
      const engine = coveo.buildSearchEngine({
        configuration: {
          accessToken:
            process.env.COVEO_ACCESS_TOKEN && process.env.COVEO_ACCESS_TOKEN.length > 0
              ? process.env.COVEO_ACCESS_TOKEN
              : '',
          organizationId,
          organizationEndpoints: coveo.getOrganizationEndpoints(organizationId),
          search: {
            pipeline:
              process.env.COVEO_PIPELINE &&
              process.env.COVEO_PIPELINE.length > 0 &&
              !coveoQuery &&
              !searchHub
                ? process.env.COVEO_PIPELINE
                : process.env.COVEO_LISTING_PIPELINE,
            searchHub: searchHub ?? 'AvidComGlobalSearch',
          },
          analytics: {
            analyticsClientMiddleware: (...[, payload]) => {
              pushCoveoSearchEvent(payload);
              return payload;
            },
          },
          preprocessRequest: (request, clientOrigin) => {
            const language = locale();
            if (language == null || language.length === 0) {
              return request;
            }
            const defaultCoveoAq = `@language==(${getLanguageName(
              language
            )}) OR @language==(${language}) OR (NOT @language)`;

            if (clientOrigin == 'searchApiFetch') {
              const body = JSON.parse(request.body?.toString() ?? '');
              if (!ignoreLangTabs.includes(body['tab'])) {
                body.aq = coveoQuery ? `${coveoQuery} AND (${defaultCoveoAq})` : defaultCoveoAq;
              }
              request.body = JSON.stringify(body);
            }

            return request;
          },
        },
      });

      setSearchEngine(engine);
    };

    initializeSearchEngine();
  }, [
    allContentLabel,
    locale,
    organizationId,
    coveoQuery,
    dispatch,
    lang,
    searchHub,
    currencyIsoCode,
    cart,
  ]);

  const layoutStructure = (
    <div
      className={`default-layout-page flex flex-col justify-between relative ${gtWalshiemAvidRegular.variable} ${gtWalshiemAvidLight.variable} ${gtWalshiemAvidMedium.variable} ${gtWalshiemAvidBold.variable} ${gtWalshiemAvidRegularOblique.variable} `}
    >
      <header className="sticky top-0 z-50">
        {route && <Placeholder name="jss-header" rendering={route} />}
        <div className="absolute w-full" id="sticky-navigation"></div>
        <div id="local-navigation-mobile"></div>
      </header>
      <main className="mb-auto">{route && <Placeholder name="jss-main" rendering={route} />}</main>
      <footer className="bg-almost-black text-white p-8">
        {route && <Placeholder name="jss-footer" rendering={route} />}
      </footer>
    </div>
  );
  return (
    <>
      <Head>
        <title>{((route?.fields?.metaPageTitle as Field)?.value as string) || 'Page'}</title>
        <MetaData sitecore={layoutData.sitecore} />
        <meta charSet="UTF-8" />
        <link rel="icon" href="https://cdn.avid.com/avidcom/images/shared/icons/favicon.ico" />
      </Head>
      {editorActive || !searchEngine ? (
        layoutStructure
      ) : (
        <CoveoSearchEngineContext.Provider value={searchEngine}>
          {layoutStructure}
        </CoveoSearchEngineContext.Provider>
      )}
      <div>{route && <Placeholder name="jss-extras" rendering={route} />}</div>
    </>
  );
};

export default DefaultLayout;
