import { debounce } from '../common/debounce';
import { getMultiProductPricing } from './product-api';
import { Price } from './model/product-models';

export class PriceQueue {
  queue: Array<string> = [];
  resultsCallback: Array<(results: Array<Price>) => void> = [];

  private msToWaitBeforeFetch = 500;
  fetchData = async (currencyIsoCode: string): Promise<void> => {
    const response = await getMultiProductPricing(this.queue, currencyIsoCode);
    this.queue = [];
    if (this.resultsCallback != null) {
      for (let i = 0; i < this.resultsCallback.length; i++) {
        this.resultsCallback[i](response.data);
      }
    }
  };

  debouncedMethod = debounce(this.fetchData, this.msToWaitBeforeFetch);

  queuePriceFetch = (
    productId: string,
    currencyIsoCode: string,
    callback: (results: Array<Price>) => void
  ): void => {
    if (this.queue.includes(productId)) {
      this.resultsCallback.push(callback);
      this.debouncedMethod(currencyIsoCode);
      return;
    }

    this.queue.push(productId);
    this.resultsCallback.push(callback);
    this.debouncedMethod(currencyIsoCode);
  };
}

export const priceQueue = new PriceQueue();
