import React, { useEffect, useState } from 'react';
import { Field, RichText, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import CoveoRecommendationsCard from './CoveoRecommendationsCard';
import { CoveoRecommendedProductDetails } from './coveo-recommendations';
import Carousel from 'components/carousel/Carousel';
import Spinner from 'components/spinner/Spinner';
import { getMultiProductPricing } from 'lib/commerce/product/product-api';
import { useAppSelector } from 'lib/store/hooks';
import ProductRecommendationsCarouselButtons from 'components/product-recommendations/ProductRecommendationsCarouselButtons';
import { TAILWIND, useScreenSize } from 'lib/hooks/use-screen-size';
import { trackWindowScroll } from 'react-lazy-load-image-component';

type CoveoRecommendationsCarouselProps = {
  title: Field<string>;
  description: Field<string>;
  fetchPriceFromSalesforce: Field<boolean>;
  productsArray: CoveoRecommendedProductDetails[];
  scrollPosition: Record<string, number> | null;
};

const maxCardWidth = 300; //px

const CoveoRecommendationsCarousel: React.FC<CoveoRecommendationsCarouselProps> = ({
  title,
  description,
  fetchPriceFromSalesforce,
  productsArray,
  scrollPosition,
}) => {
  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode) ?? 'USD';
  const screenSize = useScreenSize();
  const [updatedProductsArray, setUpadatedProductsArray] = useState<
    CoveoRecommendedProductDetails[] | null
  >(null);

  const getMaxWidth = (): string => {
    if (!updatedProductsArray) {
      return 'w-full';
    }
    if (screenSize.width < TAILWIND.md) {
      return 'w-80';
    }
    if (screenSize.width < TAILWIND.lg) {
      return updatedProductsArray.length < 2
        ? 'w-80'
        : `w-${maxCardWidth * 2} w-[${maxCardWidth * 2}px]`;
    }
    if (updatedProductsArray.length > 3) {
      return 'w-full';
    }
    return updatedProductsArray.length < 2
      ? 'w-80'
      : `w-${updatedProductsArray.length * maxCardWidth} w-[${
          updatedProductsArray.length * maxCardWidth
        }px]`;
  };

  useEffect(() => {
    if (!productsArray) {
      return;
    }
    if (!fetchPriceFromSalesforce?.value) {
      setUpadatedProductsArray(productsArray.filter((item) => item.price.price > 0));
      return;
    }
    const productIds: string[] = productsArray.map((product) => product.productId);
    const fetchProductDataList = async (): Promise<void> => {
      try {
        const results = await getMultiProductPricing(productIds, currencyIsoCode);
        const updatedProducts = productsArray.map((product) => {
          const priceData = results?.data.find((item) => item.productId === product.productId);
          if (priceData && priceData.unitPrice > 0) {
            return {
              ...product,
              priceSF: priceData,
            };
          } else if (!priceData && product.price?.price > 0) {
            return product;
          }
          return null;
        });
        setUpadatedProductsArray(
          updatedProducts.filter((item) => item !== null) as CoveoRecommendedProductDetails[]
        );
      } catch (error) {
        console.error('Error fetching product prices', error);
      }
    };
    fetchProductDataList();
  }, [currencyIsoCode, fetchPriceFromSalesforce?.value, productsArray, setUpadatedProductsArray]);

  return (
    <>
      {updatedProductsArray === null && <Spinner />}
      {updatedProductsArray && updatedProductsArray.length > 0 && (
        <div className="coveo-cart-recommendations relative mb-15">
          {title && (
            <Text
              tag="h2"
              field={title}
              className="font-bold text-2xl leading-29.5 mb-6 text-center"
            />
          )}
          {description && (
            <RichText
              field={description}
              tag="div"
              className="rte richTextBl max-w-content mx-auto text-base-darker [&_a]:underline [&_h1]:mb-10 [&_h2]:mb-10 [&_h3]:mb-10 [&_h4]:mb-10 [&_h5]:mb-10 [&_h6]:mb-10 [&_p]:mb-10 [&_a]:text-primary p-1 px-6"
            />
          )}
          <div className="flex flex-row justify-center realative">
            <div className={`${getMaxWidth()}`}>
              <div className="relative w-full">
                <Carousel
                  MaxItemsPerSlideOnDesktop="3"
                  MaxItemsPerSlideOnTablet="2"
                  MaxItemsPerSlideOnMobile="1"
                  tabletSize={TAILWIND.md}
                  desktopSize={TAILWIND.lg}
                  sliderClass="flex flex-row justify-center flex-grow !pb-1"
                  itemClass="justify-center w-1/3"
                  buttonGroup={<ProductRecommendationsCarouselButtons />}
                >
                  {updatedProductsArray?.map((product, index) => (
                    <CoveoRecommendationsCard
                      key={index}
                      product={product}
                      fetchPriceFromSalesforce={fetchPriceFromSalesforce.value}
                      scrollPosition={scrollPosition}
                    />
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default trackWindowScroll(CoveoRecommendationsCarousel);
