import {
  Text,
  Field,
  withDatasourceCheck,
  ImageField,
  LinkField,
  isEditorActive,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import { default as Link } from 'components/foundation/non-sitecore/NextLink';
import { useEffect } from 'react';
import {
  AnalyticsProductItem,
  pushSelectPromotion,
  pushViewPromotion,
} from 'lib/google-analytics/commerce';
import { useInView } from 'react-intersection-observer';
import { useAppSelector } from 'lib/store/hooks';

type PromoStripProps = ComponentProps & {
  fields: {
    heading: Field<string>;
    description: Field<string>;
    image: ImageField;
    ctaButton: LinkField;
    theme: Field<string>;
    noFollow?: Field<boolean>;
  };
};

const PromoStrip = (props: PromoStripProps): JSX.Element => {
  const editorActive = isEditorActive();
  const theme = props.fields?.theme?.value;
  const containsImage: boolean = props.fields?.image?.value?.src != null;
  const containerCss =
    theme === 'dark' || containsImage
      ? 'bg-neutral-darkest text-white'
      : 'bg-base-lighter text-almost-black';
  const btnCss = theme === 'dark' || containsImage ? 'btn-primary-dark' : 'btn-feature';

  const promotionName = 'Promo Stip';
  const promotionLocation = 'Multiple';
  const isPromotionValid =
    editorActive ||
    props.fields.heading.value.length != 0 ||
    props.fields.description.value.length != 0 ||
    containsImage;

  const [ref, promoOnScreen] = useInView({ triggerOnce: true, threshold: 0.25 });
  const customerInfo = useAppSelector((state) => state?.customer?.customerData);

  useEffect(() => {
    if (props.fields == null || !promoOnScreen) {
      return;
    }

    const name =
      props?.fields?.heading?.value?.length != 0
        ? props.fields.heading.value
        : props?.fields?.description?.value;

    pushViewPromotion(
      promotionName,
      promotionLocation,
      `PromoStrip_${props.rendering.uid}`,
      name,
      {} as AnalyticsProductItem,
      (customerInfo?.contactId as string) || ''
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [promoOnScreen]);

  const onClickLinkHandler = (): void => {
    const name =
      props.fields.heading.value.length != 0
        ? props.fields.heading.value
        : props.fields.description.value;

    pushSelectPromotion(
      promotionName,
      promotionLocation,
      `PromoStrip_${props.rendering.uid}`,
      name
    );
  };

  return isPromotionValid ? (
    <div
      ref={ref}
      className={`${containerCss} ${
        containsImage
          ? 'grid grid-cols-1 md:grid-cols-12 md:grid-rows-1'
          : 'flex justify-center items-center'
      }   mx-6 xl:max-w-content xl:mx-auto sm:mb-10 lg:mb-20`}
    >
      <div
        className={`flex ${
          containsImage
            ? 'col-span-7 p-8 pb-0 md:pb-8 flex-col justify-center items-start'
            : 'flex-col py-6 items-center md:flex-row'
        }`}
      >
        <div>
          <Text
            field={props?.fields?.heading}
            tag="h4"
            className={`${containsImage ? '' : 'text-center'} mb-2 md:mb-1`}
          />
          <Text
            field={props?.fields?.description}
            tag="div"
            className={`${containsImage ? '' : 'text-center'}`}
          />
        </div>
        <Link
          field={props.fields.ctaButton}
          linkCustomizations={props.fields}
          onClick={(): void => onClickLinkHandler()}
          className={`${btnCss} mt-4 ${containsImage ? '' : 'md:ml-[40px] md:mt-0'} inline-block`}
        />
      </div>
      {containsImage && (
        <div className="grid row-span-5 md:col-span-5 relative">
          <NextImage field={props.fields.image} />
          <div className="absolute overlay w-full h-1/2 bg-gradient-to-b md:w-1/2 md:h-full md:bg-gradient-to-r from-neutral-darkest to-[#0a0b0e00]"></div>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default withDatasourceCheck()<PromoStripProps>(PromoStrip);
