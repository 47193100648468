import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import useDropdownMenu from 'react-accessible-dropdown-menu-hook';

export type DropdownMenuProps = {
  options: { value: string; label: string }[];
  defaultValue?: { value: string; label: string };
  placeholder?: string;
  className?: string;
  id?: string;
  required?: boolean;
  requiredValidationMessage?: string;
  triggerErrorState?: boolean;
  passValueToParentFunction: (value: string) => void;
};

function MarketplaceDropDown(props: DropdownMenuProps): JSX.Element | null {
  const {
    options,
    defaultValue,
    className,
    passValueToParentFunction,
    id,
    placeholder,
    required,
    requiredValidationMessage,
    triggerErrorState,
  } = props;

  const [selectedOption, setSelectedOption] = useState(
    placeholder ? null : defaultValue || options[0]
  );
  const [displayPlaceholder, setDisplayPlaceholder] = useState(true);
  const { buttonProps, itemProps, isOpen, setIsOpen } = useDropdownMenu(options.length);

  const selectOptionHandler = (option: { value: string; label: string }): void => {
    setIsOpen(false);
    setDisplayPlaceholder(false);
    if (selectedOption == null || selectedOption?.value !== option.value) {
      setSelectedOption(option);
      passValueToParentFunction(option.value);
    }
  };

  if (options.length <= 0) {
    return null;
  }

  return (
    <div className={`${className} text-almost-black flex flex-col`}>
      {id && (
        <select
          id={id}
          className="appearance-none peer absolute"
          onFocus={(): void => setIsOpen(true)}
        />
      )}

      <button
        className="flex justify-between items-center h-[38px] bg-mp-background-darker rounded-sm px-3 overflow-x-hidden text-ellipsis"
        role="button"
        {...buttonProps}
        tabIndex={0}
        type="button"
      >
        <div>{displayPlaceholder && placeholder ? placeholder : selectedOption?.label}</div>
        <div>
          <FontAwesomeIcon
            icon={isOpen ? faSortUp : faSortDown}
            className={`my-auto relative pl-[14px] text-xl ${isOpen ? 'top-1' : 'bottom-1'}`}
          />
        </div>
      </button>
      <div className={`relative ${isOpen ? 'block' : 'hidden'}`}>
        <div
          role="menu"
          className="flex flex-col rounded-sm absolute z-[5] w-full max-h-[200px] overflow-y-auto overflow-x-hidden"
        >
          {options?.map((option, index) => (
            <a
              {...itemProps[index]}
              key={index}
              onClick={(): void =>
                selectOptionHandler({ value: option.value, label: option.label })
              }
              className={`px-3 py-1 align-middle cursor-pointer hover:bg-mp-btn-primary hover:text-mp-txt-almostBlack
                disabled:text-base-normal focus-visible:outline-none focus-visible:shadow-[inset_0_0_0_2px_rgba(0,0,0,0.3)]
                focus-visible:shadow-accent-cool ${
                  selectedOption?.value === option.value
                    ? 'bg-mp-background-darker text-mp-txt-primary border border-mp-txt-primary'
                    : 'bg-mp-background-darker'
                } whitespace-normal text-ellipsis`}
            >
              {option.label}
            </a>
          ))}
        </div>
      </div>
      {required && triggerErrorState && (
        <p className="mt-1 text-xs text-danger">{requiredValidationMessage || ''}</p>
      )}
    </div>
  );
}

export default MarketplaceDropDown;
