import React, { useEffect, useCallback, useMemo } from 'react';
import {
  createEncryptedFastSpringCheckoutSession,
  setBillingAddress,
} from 'components/checkout/checkout-slice';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { useState } from 'react';
import { useSession } from 'next-auth/react';
import { useI18n } from 'next-localization';
import Spinner from 'components/spinner/Spinner';
import { InputItem, PhoneItem, DropDownItem } from './BillingAddrFormItems';
import { getCountriesPostalCodeRules } from 'lib/myavid/myavid-api';
import { MyAvidCountriesPostalCodesData } from 'lib/myavid/model/myavid-models';
import { changeCurrency } from 'components/cart/cart-slice';
import { getBearerToken } from 'lib/authentication/account-provider';
import { useRouter } from 'next/router';

const MarketplaceBillingAddress = (): JSX.Element => {
  const { t } = useI18n();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { data: session } = useSession();
  const cartItemData = useAppSelector((state) => state.cart.cartItemsData);
  const cartCurrency = cartItemData?.cartSummary.currencyIsoCode as string;
  const bearerToken = useMemo(() => getBearerToken(session), [session]);
  const customerInfo = useAppSelector((state) => state.customer);
  const customerDataStatus = customerInfo?.customerDataStatus;
  const countries = useAppSelector((state) => state.countries.countriesData);
  const countryOptions = countries.map((country) => {
    return { value: country.countryCode, label: country.countryName };
  });
  countryOptions.unshift({ value: 'US', label: 'United States' });
  const email = session?.user?.email;

  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [addressLine1, setAddressLine1] = useState<string>('');
  const [addressLine2, setAddressLine2] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [countryName, setCountryName] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [postalCodesRules, setPostalCodesRules] = useState<MyAvidCountriesPostalCodesData[] | null>(
    null
  );

  const [countryValidationError, setCountryValidationError] = useState<boolean>(false);
  const [stateValidationError, setStateValidationError] = useState<boolean>(false);
  const [firstNameValueValidationError, setFirstNameValueValidationError] =
    useState<boolean>(false);
  const [lastNameValueValidationError, setLastNameValueValidationError] = useState<boolean>(false);
  const [billingAddressValueValidationError, setbillingAddressValueValidationError] =
    useState<boolean>(false);
  const [billingCityValueValidationError, setbillingCityValueValidationError] =
    useState<boolean>(false);
  const [zipcodeValueValidationError, setZipcodeValueValidationError] = useState<boolean>(false);
  const [phoneNumberValueValidationError, setPhoneNumberValueValidationError] =
    useState<boolean>(false);
  const [dataExists, setDataExists] = useState<boolean>(false);

  const getCountryRegions = useCallback(
    (country: string): { value: string; label: string }[] => {
      return (
        countries
          .find((countryData) => country === countryData.countryCode)
          ?.regions?.map((region) => {
            return { value: region.regionCode, label: region.regionName };
          }) || []
      );
    },
    [countries]
  );

  const getCountryName = useCallback(
    (country: string): string => {
      return (
        countries.find((countryData) => countryData.countryCode === country)?.countryName || ''
      );
    },
    [countries]
  );

  const [stateOptions, setStateOptions] = useState<{ value: string; label: string }[]>([]);

  const onSelectCountryHandler = (value: string): void => {
    setStateOptions([]);
    setState('');
    setCountry(value);
    if (value.length <= 0) {
      setCountryValidationError(true);
    } else {
      setCountryValidationError(false);
    }
    setStateOptions(getCountryRegions(value));
  };

  const onSelectStateHandler = (value: string): void => {
    setState(value);
    if (value.length <= 0 && !(stateOptions.length <= 0)) {
      setStateValidationError(true);
    } else {
      setStateValidationError(false);
    }
  };

  const isPostalCodeValid = (): boolean => {
    const rule = postalCodesRules?.find((el) => el.countryCode === country);
    if (!rule || !rule.zipRegex) {
      return true;
    }
    const regex = new RegExp(rule.zipRegex);
    return regex.test(postalCode);
  };

  const onSubmitBillingAddressHandler = async (
    e: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    e.preventDefault();

    setFirstNameValueValidationError(firstName.length <= 0);
    setLastNameValueValidationError(lastName.length <= 0);
    setbillingAddressValueValidationError(addressLine1.length <= 0);
    setbillingCityValueValidationError(city.length <= 0);
    setZipcodeValueValidationError(postalCode.length <= 0 || !isPostalCodeValid());
    setPhoneNumberValueValidationError(phoneNumber.length < 7);
    setCountryValidationError(country.length <= 0);
    setStateValidationError(state.length <= 0 && !(stateOptions.length <= 0));

    const address = {
      firstName,
      lastName,
      line1: addressLine1,
      line2: addressLine2,
      city,
      state,
      postalCode,
      country,
      phoneNumber,
    };

    if (
      firstName.length > 0 &&
      lastName.length > 0 &&
      addressLine1.length > 0 &&
      city.length > 0 &&
      postalCode.length > 0 &&
      isPostalCodeValid() &&
      phoneNumber.length > 6 &&
      country.length > 0 &&
      state.length > 0
    ) {
      const countryCurrency =
        countries.find((countryData) => country === countryData.countryCode)?.currencyISoCode ??
        cartCurrency;

      if (countryCurrency !== cartCurrency) {
        await dispatch(
          changeCurrency({ currencyIsoCode: countryCurrency, bearerToken: bearerToken })
        );
      }
      dispatch(setBillingAddress(address));
      dispatch(
        createEncryptedFastSpringCheckoutSession({
          billingDetails: {
            email: email,
            firstName: firstName,
            lastName: lastName,
            phone: phoneNumber,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            city: city,
            region: state,
            country: country,
            postalCode: postalCode,
          },
          store: 'Webstore',
          bearerToken: bearerToken,
        })
      );
    }
  };

  useEffect(() => {
    getCountriesPostalCodeRules()
      .then((rules) => {
        setPostalCodesRules(rules);
      })
      .catch((error) => {
        console.log(
          'Got error by requesting postal codes rules from MarketplaceBillingAddress: ',
          error
        );
      });
  }, []);

  useEffect(() => {
    if (
      !countries.length ||
      customerDataStatus !== 'succeeded' ||
      (!postalCodesRules && window.location.hostname !== 'localhost')
    ) {
      return;
    }
    setFirstName(customerInfo?.customerData?.firstName || '');
    setLastName(customerInfo?.customerData?.name.split(' ')[1] || '');
    setAddressLine1(customerInfo?.customerData?.billingStreet ?? '');
    setCity(customerInfo?.customerData?.billingCity ?? '');
    setState(customerInfo?.customerData?.billingState ?? '');
    setPostalCode(customerInfo?.customerData?.billingPostalCode ?? '');
    setCountry(customerInfo?.customerData?.billingCountry ?? '');
    if (customerInfo?.customerData?.billingCountry) {
      setStateOptions(getCountryRegions(customerInfo?.customerData?.billingCountry));
      setCountryName(getCountryName(customerInfo?.customerData?.billingCountry));
    }
    setPhoneNumber(customerInfo?.customerData?.phoneNo ?? '');
    setDataExists(true);
  }, [
    countries,
    getCountryRegions,
    getCountryName,
    postalCodesRules,
    customerInfo?.customerData,
    customerDataStatus,
  ]);

  const formItems = [
    {
      label: 'MP_Billing_FirstName',
      value: firstName,
      isRequired: true,
      requiredValidationMessage: t('MP_Firstname_Error'),
      triggerErrorState: firstNameValueValidationError,
      action: setFirstName,
      type: 'input',
    },
    {
      label: 'MP_Billing_LastName',
      value: lastName,
      isRequired: true,
      requiredValidationMessage: t('MP_Lastname_Error'),
      triggerErrorState: lastNameValueValidationError,
      action: setLastName,
      type: 'input',
    },
    {
      label: 'MP_Billing_PhoneNumber',
      isRequired: true,
      requiredValidationMessage: t('MP_PhoneNumber_Error'),
      triggerErrorState: phoneNumberValueValidationError,
      action: setPhoneNumber,
      type: 'phoneNumber',
    },
    {
      label: 'MP_Billing_AddressLine1',
      value: addressLine1,
      isRequired: true,
      requiredValidationMessage: t('MP_AddressLine_Error'),
      triggerErrorState: billingAddressValueValidationError,
      action: setAddressLine1,
      type: 'input',
    },
    {
      label: 'MP_Billing_AddressLine2',
      value: addressLine2,
      isRequired: false,
      action: setAddressLine2,
      type: 'input',
    },
    {
      label: 'MP_Billing_Country',
      isRequired: true,
      passValueToParentFunction: onSelectCountryHandler,
      type: 'dropDown',
      requiredValidationMessage: t('MP_Country_Error'),
      defaultValue: {
        value: country,
        label: countryName || t('MP_Billing_Country'),
      },
      options: countryOptions,
      triggerErrorState: countryValidationError,
    },
    {
      label: 'MP_Billing_State',
      isRequired: true,
      passValueToParentFunction: onSelectStateHandler,
      type: 'dropDown',
      requiredValidationMessage: t('MP_State_Error'),
      defaultValue: {
        value: state,
        label:
          stateOptions.find((region) => region.value === state)?.label || t('MP_Billing_State'),
      },
      options: stateOptions,
      triggerErrorState: stateValidationError,
      toShow: stateOptions.length > 0 || state.length > 0,
    },
    {
      label: 'MP_Billing_City',
      value: city,
      isRequired: true,
      requiredValidationMessage: t('MP_City_Error'),
      triggerErrorState: billingCityValueValidationError,
      action: setCity,
      type: 'input',
    },
    {
      label: 'MP_Billing_Postal_Code',
      value: postalCode,
      isRequired: true,
      requiredValidationMessage: t('MP_PostalCode_Error'),
      triggerErrorState: zipcodeValueValidationError,
      action: setPostalCode,
      type: 'input',
    },
  ];

  return (
    <div id="billing-address--marketplace" className={`${!dataExists ? 'h-96' : 'p-2.5'}`}>
      {!dataExists && <Spinner color="#20BBD0" />}
      {dataExists && (
        <div className="relative w-full p-2.5 text-mp-txt-neutralLight bg-mp-background-card m-auto after:clear-both whitespace-nowrap">
          <div className="w-full">
            <h2 className="my-5">{t('MP_Edit_Billing_Addr')}</h2>
          </div>
          <form onSubmit={(e): Promise<void> => onSubmitBillingAddressHandler(e)} noValidate>
            <p className="italic mb-2.5">
              <span className="text-danger">* </span>
              {t('MP_Required')}
            </p>

            {formItems.map(
              (
                {
                  label,
                  value,
                  isRequired,
                  action = (): void => undefined,
                  type,
                  passValueToParentFunction = (): void => undefined,
                  requiredValidationMessage,
                  options = [],
                  triggerErrorState,
                  defaultValue,
                  toShow = true,
                },
                index
              ) =>
                type === 'input' ? (
                  <InputItem
                    key={label + index}
                    label={label}
                    value={value}
                    isRequired={isRequired}
                    requiredValidationMessage={requiredValidationMessage}
                    triggerErrorState={triggerErrorState}
                    action={action}
                  />
                ) : type === 'phoneNumber' ? (
                  <PhoneItem
                    key={label + index}
                    label={label}
                    phoneNumber={phoneNumber}
                    isRequired={isRequired}
                    requiredValidationMessage={requiredValidationMessage}
                    triggerErrorState={triggerErrorState}
                    action={action}
                  />
                ) : (
                  <DropDownItem
                    key={label + index}
                    label={label}
                    isRequired={isRequired}
                    passValueToParentFunction={passValueToParentFunction}
                    requiredValidationMessage={requiredValidationMessage}
                    options={options}
                    triggerErrorState={triggerErrorState}
                    defaultValue={defaultValue}
                    toShow={toShow}
                  />
                )
            )}
            <div className="mt-9 text-center">
              <button
                type="submit"
                className={`bg-mp-btn-primary hover:bg-mp-btn-light text-mp-txt-bright text-sm px-3.5 py-1 rounded w-3/4 sm:w-44 block sm:inline m-auto`}
              >
                {t('MP_Save_Continue')}
              </button>
              <button
                type="button"
                className={`text-mp-txt-bright text-sm px-3.5 py-1 rounded 
                  w-3/4 sm:w-44 bg-mp-background-darker hover:bg-mp-background-neutral block sm:inline m-auto my-3 sm:my-0 sm:ml-3`}
                onClick={(): Promise<boolean> => router.push('/marketplace/cart')}
              >
                {t('mp_cancel')}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};
export default MarketplaceBillingAddress;
