import { apm as apmBase } from '@elastic/apm-rum';

import { ApmBase } from '@elastic/apm-rum';
import { getCookie } from 'cookies-next';
import { SsrErrorsObject } from 'lib/component-props';
import { getSession } from 'next-auth/react';

export let apm: ApmBase | undefined;

export const apmRUMInit = async (): Promise<void> => {
  if (
    typeof window === 'undefined' ||
    process.env.NEXT_PUBLIC_ELASTIC_APM_SERVICE_NAME === undefined ||
    process.env.NEXT_PUBLIC_ELASTIC_APM_SERVER_URL === undefined ||
    process.env.NEXT_PUBLIC_ELASTIC_APM_ENVIRONMENT === undefined ||
    process.env.NEXT_PUBLIC_CLIENT_APM_DISABLED === 'true'
  ) {
    return;
  }
  apm = apmBase;
  apm.init({
    serviceName: process.env.NEXT_PUBLIC_ELASTIC_APM_SERVICE_NAME,
    serverUrl: process.env.NEXT_PUBLIC_ELASTIC_APM_SERVER_URL,
    environment: process.env.NEXT_PUBLIC_ELASTIC_APM_ENVIRONMENT,
    transactionSampleRate: process.env.NEXT_PUBLIC_ELASTIC_TRANSACTION_SAMPLE_RATE
      ? +process.env.NEXT_PUBLIC_ELASTIC_TRANSACTION_SAMPLE_RATE
      : 1,
    eventsLimit: 150,
  });
};

export const sendElasticClientLogs = (messages: string[]): void => {
  messages.forEach((message) => apm?.captureError(message));
};

export const sendElasticSSRLogs = async (errors: SsrErrorsObject): Promise<void> => {
  let userEmail;
  userEmail = getCookie('user-email');
  if (!userEmail) {
    const session = await getSession();
    userEmail = session?.user?.email;
  }
  const messageObj = {
    Page: errors.page + '; ',
    ComponentName: errors.componentName + '; ',
    User: userEmail + '; ',
    'TimeStamp UTC': new Date().toISOString() + '; ',
  };
  errors.errorList.forEach((error) => {
    apm?.captureError(
      'SSR Error: ' +
        JSON.stringify({
          ...messageObj,
          error,
        })
    );
  });
};
