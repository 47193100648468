import {
  Field,
  LinkField,
  ImageField,
  withDatasourceCheck,
  isEditorActive,
  RichText,
  //   Text,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { BrightcoveVideoProps } from '../../components/video/brightcove-video-props';
import NextLink from 'components/foundation/non-sitecore/NextLink';
import NextImage from 'components/foundation/non-sitecore/NextImage';
import BrightcoveEmbededVideo from 'components/video/brightcove/BrightcoveEmbededVideo';

export type BlipsBannerProps = ComponentProps & {
  fields: {
    anchorid: Field<string>;
    bannerlink: LinkField;
    bannerImage: ImageField;
    bannerImageMobile: ImageField;
    bannerTextContent: Field<string>;
    mobileTextContent: Field<string>;
    hideMobileTextContent?: Field<boolean>;
    bannerTextLogo: ImageField;
    sequenceContent: Field<string>;
    ribbonLink: LinkField;
    ribbonText: Field<string>;
    hideGraphics: Field<boolean>;
    ariaLabel: Field<string>;
    bannerOptionalContainer: Field<string>;
    videos?: BrightcoveVideoProps[];
  };
  params: {
    DisplayRibbon: Field<boolean>;
    UseSequenceGraphics: Field<boolean>;
    BannerTextPosition: string;
    BannerTextBackground: string;
    RibbonColorPicker: string;
    RibbonPosition: string;
    BannerMobileTextBackground: string;
    BannerTextColor: string;
    BannerMobileTextColor: string;
    BannerOptionalContainerBackground: string;
  };
};

const BlipsBanner = (props: BlipsBannerProps): JSX.Element => {
  const editorActive = isEditorActive();
  const video = props.fields?.videos?.[0];
  const bannerHeight = props.fields.bannerImage?.value?.height;
  const blipsParams = props?.params;
  const bannerLinkFilled =
    props.fields?.bannerlink && props.fields?.bannerlink?.value?.href !== '' ? true : false;
  let videoId, playerId;
  if (video) {
    videoId = video.fields?.videoReference?.[0]?.fields?.ID?.value as string;
    playerId = video.fields?.player?.fields?.ID?.value as string;
  }

  const bannerSizes = {
    600: 'h-600',
    900: 'h-900',
  };
  const blipColorVariants = {
    green: 'text-avidgreen',
    purple: 'text-avidpurple',
    teal: 'text-teal',
    black: 'text-black',
    white: 'text-white',
    yellow: 'text-sketchyellow',
    blue: 'text-blue',
    punch: 'text-tertiary',
    tealblue: 'text-tealblue',
    sketchblue: 'text-sketchblue',
  };
  const blipBackgroundColorVariants = {
    green: 'bg-avidgreen',
    purple: 'bg-avidpurple',
    teal: 'bg-teal',
    black: 'bg-black',
    white: 'bg-white',
    yellow: 'bg-sketchyellow',
    blue: 'bg-blue',
    punch: 'bg-tertiary',
    tealblue: 'bg-tealblue',
    sketchblue: 'bg-sketchblue',
  };
  const bannerPositions = {
    'Bottom-Left':
      'w-685 items-center absolute [&>.bannerTextArea]:absolute [&>.bannerTextArea]:bottom-5p bottom-0 left-0 top-0 px-[4%]',
    'Bottom-Middle':
      'w-80p items-center m-auto absolute [&>.bannerTextArea]:absolute [&>.bannerTextArea]:bottom-5p  bottom-0 left-0 right-0 top-0 px-[4%]',
    'Bottom-Right':
      'w-685 items-center absolute[&>.bannerTextArea]:absolute [&>.bannerTextArea]:bottom-5p bottom-0 right-0 top-0 px-[4%]',
    Middle: 'w-685 m-auto  flex items-center absolute bottom-0 left-0 right-0  top-0 px-[4%] ',
    'Middle-Left': 'w-685  flex items-center absolute bottom-0 left-0 top-0 px-[4%] ',
    'Middle-Right': 'w-685  flex items-center absolute bottom-0 right-0 top-0 px-[4%] ',
    'Top-Left':
      'w-685  flex  absolute  left-0 [&>.bannerTextArea]:absolute [&>.bannerTextArea]:top-5p  px-[4%]',
    'Top-Middle':
      'w-685  flex items-center m-auto [&>.bannerTextArea]:absolute [&>.bannerTextArea]:top-5p absolute  px-[4%] ',
    'Top-Right':
      'w-685  flex  absolute [&>.bannerTextArea]:absolute [&>.bannerTextArea]:top-5p  right-0  px-[4%] ',
  };
  const ribbonPositions = {
    'Bottom-Left': 'bottom-7p [&>.blipsSequence]:hidden  [&>.blipsRibbon]:left-0 ',
    'Bottom-Middle':
      'bottom-7p [&>.blipsSequence]:hidden [&>.blipsRibbon]:m-auto [&>.blipsRibbon]:left-0 [&>.blipsRibbon]:right-0',
    'Bottom-Right': 'flex bottom-7p  [&>.blipsRibbon]:right-0 ',
    Middle:
      'm-auto h-15 bottom-0 top-0 [&>.blipsRibbon]:bottom-0  [&>.blipsRibbon]:m-auto [&>.blipsRibbon]:top-0 [&>.blipsRibbon]:left-0 [&>.blipsRibbon]:right-0 [&>.blipsSequence]:hidden',
    'Middle-Left':
      ' h-15 bottom-0 top-0 [&>.blipsRibbon]:bottom-0 [&>.blipsRibbon]:top-0 [&>.blipsRibbon]:left-0  [&>.blipsSequence]:hidden',
    'Middle-Right':
      'm-auto h-15 bottom-0 top-0 [&>.blipsRibbon]:bottom-0 [&>.blipsRibbon]:top-0  [&>.blipsRibbon]:right-0 [&>.blipsSequence]:hidden',
    'Top-Left': 'top-10p [&>.blipsSequence]:hidden  [&>.blipsRibbon]:left-0 ',
    'Top-Middle':
      'top-10p [&>.blipsSequence]:hidden [&>.blipsRibbon]:m-auto [&>.blipsRibbon]:left-0 [&>.blipsRibbon]:right-0',
    'Top-Right': 'flex  top-10p   [&>.blipsRibbon]:right-0 ',
  };
  const bannerMarkup = (
    <div className="blipsbanner">
      <div className="hidden lg:block richTextBl text-base-darker">
        <div
          className={`block relative w-full ${
            bannerSizes[bannerHeight as keyof typeof bannerSizes]
          }`}
        >
          <NextImage
            field={props.fields.bannerImage}
            className={`object-cover   object-center w-full`}
            layout="fill"
            priority={true}
          />
          {(props.fields.bannerTextContent?.value || editorActive) && (
            <div
              className={`${
                bannerPositions[blipsParams.BannerTextPosition as keyof typeof bannerPositions]
              }`}
            >
              <div className="p-5 bannerTextArea">
                {(props.fields.bannerTextLogo?.value || editorActive) && (
                  <div className=" ">
                    <NextImage
                      field={props.fields.bannerTextLogo}
                      className={`h-90 w-auto max-w-full`}
                      priority={true}
                    />
                  </div>
                )}
                <RichText
                  tag="div"
                  field={props.fields.bannerTextContent}
                  className={` m-auto text-white    [&_a]:underline [&_.btn-*]:no-underline [&_h1]:mb-10 [&_h2]:mb-10 [&_h3]:mb-10 [&_h4]:mb-10 [&_h5]:mb-10 [&_h6]:mb-10 [&_p]:leading-12 [&_p]:mb-10 [&_a]:text-primary items-center ${
                    blipColorVariants[blipsParams.BannerTextColor as keyof typeof blipColorVariants]
                  } ${
                    blipBackgroundColorVariants[
                      blipsParams.BannerTextBackground as keyof typeof blipColorVariants
                    ]
                  }`}
                />
              </div>
            </div>
          )}
          <div
            className={` w-full absolute text-white    ${
              ribbonPositions[blipsParams.RibbonPosition as keyof typeof ribbonPositions]
            }`}
          >
            {((props.fields.sequenceContent?.value && props.params.UseSequenceGraphics) ||
              editorActive) && (
              <div className="blipsSequence">
                <RichText
                  tag="div"
                  field={props.fields.sequenceContent}
                  className=" m-auto  pl-1 pr-2 [&_a]:underline [&_h1]:mb-10 [&_h2]:mb-10 [&_h3]:mb-10 [&_h4]:mb-10 [&_h5]:mb-10 [&_h6]:mb-10 [&_p]:leading-12 [&_p]:mb-10 [&_a]:text-primary items-center"
                />
              </div>
            )}

            {((props.fields.ribbonText?.value && props.params?.DisplayRibbon) || editorActive) && (
              <div
                className={`blipsRibbon absolute flex  max-w-60p min-h-60  text-white px-4 ${
                  blipBackgroundColorVariants[
                    blipsParams.RibbonColorPicker as keyof typeof blipBackgroundColorVariants
                  ]
                }`}
              >
                <RichText
                  tag="div"
                  field={props.fields.ribbonText}
                  className=" m-auto pl-1 pr-2  [&_a]:underline [&_h1]:mb-10 [&_h2]:mb-10 [&_h3]:mb-10 [&_h4]:mb-10 [&_h5]:mb-10 [&_h6]:mb-10 [&_p]:leading-12 [&_p]:mb-10 [&_a]:text-primary items-center"
                />
                {(!props.fields?.hideGraphics?.value || editorActive) && (
                  <div className="h-55 w-500 block relative">
                    <span className="w-25 h-24px block border-r-2 border-white absolute m-auto bottom-0 left-0 top-0">
                      <span className="h-8 left-16 inline-block bg-white w-1px absolute m-auto bottom-0 top-0"></span>
                      <span className="h-3 left-32 inline-block bg-white w-1px absolute m-auto bottom-0 top-0"></span>
                      <span className="h-16 left-34 inline-block bg-white w-1px absolute m-auto bottom-0 top-0"></span>
                      <span className="h-5 left-36 inline-block bg-white w-1px absolute m-auto bottom-0 top-0"></span>
                      <span className="h-5 left-52 inline-block bg-white w-1px absolute m-auto bottom-0 top-0"></span>
                      <span className="h-10 left-56 inline-block bg-white w-1px absolute m-auto bottom-0 top-0"></span>
                      <span className="h-2 left-64 inline-block bg-white w-1px absolute m-auto bottom-0 top-0"></span>
                      <span className="h-14 left-72 inline-block bg-white w-1px absolute m-auto bottom-0 top-0"></span>
                      <span className="h-4 left-80 inline-block bg-white w-1px absolute m-auto bottom-0 top-0"></span>
                      <span className="h-2 left-96 inline-block bg-white w-1px absolute m-auto bottom-0 top-0"></span>
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {(props.fields.bannerOptionalContainer?.value || editorActive) && (
          <div
            className={`text-2xl leading-9 py-12 px-24  ${
              blipBackgroundColorVariants[
                blipsParams.BannerOptionalContainerBackground as keyof typeof blipColorVariants
              ]
            }`}
          >
            <RichText
              tag="div"
              field={props.fields.bannerOptionalContainer}
              className=" m-auto  [&_a]:underline [&_h1]:mb-10 [&_h2]:mb-10 [&_h3]:mb-10 [&_h4]:mb-10 [&_h5]:mb-10 [&_h6]:mb-10 [&_p]:leading-8 [&_p]:mb-10 [&_a]:text-primary items-center"
            />
          </div>
        )}
      </div>
      <div className="block lg:hidden text-white relative ">
        {(props.fields.mobileTextContent?.value || editorActive) && (
          <>
            <div
              className={`w-full    pt-15 pb-5 px-6  ${
                blipColorVariants[
                  blipsParams.BannerMobileTextColor as keyof typeof blipColorVariants
                ]
              } ${
                blipBackgroundColorVariants[
                  blipsParams.BannerMobileTextBackground as keyof typeof blipColorVariants
                ]
              }`}
            >
              {(props.fields.bannerTextLogo?.value || editorActive) && (
                <div className="text-center w-full ">
                  <NextImage
                    field={props.fields.bannerTextLogo}
                    className={`h-15 w-auto text-center m-auto `}
                    priority={true}
                  />
                </div>
              )}

              <RichText
                tag="div"
                field={props.fields.mobileTextContent}
                className=" m-auto   [&_a]:underline [&_h1]:mb-5 [&_h2]:mb-5 [&_h3]:mb-5 [&_h4]:mb-5 [&_h5]:mb-5 [&_h6]:mb-5 [&_p]:leading-8 [&_p]:mb-5 lg:[&_p]:mb-10 [&_a]:text-primary items-center"
              />
            </div>
          </>
        )}

        <div>
          <NextImage field={props.fields.bannerImageMobile} className={`w-full`} priority={true} />
        </div>
        {((props.fields.ribbonText?.value && props.params?.DisplayRibbon) || editorActive) && (
          <div className="absolute w-full bottom-7p flex right-0 left-auto flex-row min-h-60 bg-black text-white px-4">
            <RichText
              tag="div"
              field={props.fields.ribbonText}
              className=" m-auto pl-1 pr-2  [&_a]:underline [&_h1]:mb-10 [&_h2]:mb-10 [&_h3]:mb-10 [&_h4]:mb-10 [&_h5]:mb-10 [&_h6]:mb-10 [&_p]:leading-12 [&_p]:mb-10 [&_a]:text-primary items-center"
            />
            {(!props.fields?.hideGraphics?.value || editorActive) && (
              <div className="h-55 w-500 block relative">
                <span className="w-25 h-24px block border-r-2 border-white absolute m-auto bottom-0 left-0 top-0">
                  <span className="h-8 left-16 inline-block bg-white w-1px absolute m-auto bottom-0 top-0"></span>
                  <span className="h-3 left-32 inline-block bg-white w-1px absolute m-auto bottom-0 top-0"></span>
                  <span className="h-16 left-34 inline-block bg-white w-1px absolute m-auto bottom-0 top-0"></span>
                  <span className="h-5 left-36 inline-block bg-white w-1px absolute m-auto bottom-0 top-0"></span>
                  <span className="h-5 left-52 inline-block bg-white w-1px absolute m-auto bottom-0 top-0"></span>
                  <span className="h-10 left-56 inline-block bg-white w-1px absolute m-auto bottom-0 top-0"></span>
                  <span className="h-2 left-64 inline-block bg-white w-1px absolute m-auto bottom-0 top-0"></span>
                  <span className="h-14 left-72 inline-block bg-white w-1px absolute m-auto bottom-0 top-0"></span>
                  <span className="h-4 left-80 inline-block bg-white w-1px absolute m-auto bottom-0 top-0"></span>
                  <span className="h-2 left-96 inline-block bg-white w-1px absolute m-auto bottom-0 top-0"></span>
                </span>
              </div>
            )}
          </div>
        )}
        {(props.fields.bannerOptionalContainer?.value || editorActive) && (
          <div
            className={`text-xl leading-8 py-6 px-8  ${
              blipBackgroundColorVariants[
                blipsParams.BannerOptionalContainerBackground as keyof typeof blipColorVariants
              ]
            }`}
          >
            <RichText
              tag="div"
              field={props.fields.bannerOptionalContainer}
              className=" m-auto  [&_a]:underline [&_h1]:mb-10 [&_h2]:mb-10 [&_h3]:mb-10 [&_h4]:mb-10 [&_h5]:mb-10 [&_h6]:mb-10 [&_p]:leading-12 [&_p]:mb-10 [&_a]:text-primary items-center"
            />
          </div>
        )}
      </div>
    </div>
  );

  if (video) {
    return (
      <div className="w-full relative ">
        <BrightcoveEmbededVideo autoplay={true} videoId={videoId} playerId={playerId} />
      </div>
    );
  } else {
    return bannerLinkFilled ? (
      <NextLink field={props.fields.bannerlink} className="min-h-250 w-full">
        {bannerMarkup}
      </NextLink>
    ) : (
      bannerMarkup
    );
  }
};

export default withDatasourceCheck()<BlipsBannerProps>(BlipsBanner);
