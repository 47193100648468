import {
  AtomicResultList,
  AtomicSearchInterface,
  buildContext,
  AtomicSearchBox,
  AtomicPager,
  AtomicSortDropdown,
  AtomicSortExpression,
  Result,
  AtomicLayoutSection,
  AtomicDidYouMean,
  AtomicSearchBoxQuerySuggestions,
  AtomicSearchLayout,
  AtomicFacetManager,
  AtomicResultsPerPage,
  AtomicQuerySummary,
  SearchEngine,
  AtomicCategoryFacet,
} from '@coveo/atomic-react';

import { useEffect, useState } from 'react';
import { useSession } from 'next-auth/react';
import NoResults from '../atomic-components/NoResults';
import QueryError from '../atomic-components/QueryError';
import { defineCustomElements } from '@ksuamel/custom-atomic-react';
import { baseSearchResultStyles } from './base-search-results';
import dynamic from 'next/dynamic';
import { toKeyValuePair } from 'lib/utils/string-format';
import { getBearerToken } from 'lib/authentication/account-provider';
import { useMemo } from 'react';
import { addCartItem } from 'components/cart/cart-slice';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { useRouter } from 'next/router';
import PluginLandingPageTemplate from './templates/PluginLandingPageTemplate';

const CoveoScripts = dynamic(() => import('./CoveoScripts'), { ssr: false });

defineCustomElements();

type BaseSearchResultsProps = {
  listName: string;
  listId: string;
  leftRailTopContent?: JSX.Element | JSX.Element[] | boolean | null;
  leftRailBottomConntent?: JSX.Element | JSX.Element[] | boolean | null;
  hideFacets?: boolean | null;
  searchEngine: SearchEngine;
  labels: { value: string };
  currencyIsoCode: string;
  currencySymbol: string;
};

const PluginSearchResults = (props: BaseSearchResultsProps): JSX.Element => {
  const [hydrate, setHydrate] = useState(false);
  const [showFacet, setShowFacet] = useState<boolean>(false);
  const navigationState = useAppSelector((state) => state.navigation);

  const maxFacetCount = 5;
  const labels = props.labels?.value ? toKeyValuePair(props?.labels?.value) : {};
  const t = (key: string): string => {
    return labels[key] || key;
  };

  const handleToggleFacets = (): void => {
    setShowFacet(!showFacet);
  };
  const { data: session } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);
  const dispatch = useAppDispatch();
  const router = useRouter();
  useEffect(() => {
    setHydrate(true);
    // if we pass true for both it should show community results without singing in.
    buildContext(props.searchEngine).add('IsAuthenticated', session ? 'True' : 'False');
  }, [session, props.searchEngine]);

  const addToCartHandler = (productId): void => {
    if (!productId) return;
    dispatch(addCartItem({ sku: productId, quantity: 1, bearerToken: bearerToken }))
      .unwrap()
      .then(() => {
        router.push('/cart');
      });
  };

  // const removeFromCartHandler = (productId): void => {
  //   if (!productId) return;
  //   dispatch(addCartItem({ sku: productId, quantity: 1, bearerToken: bearerToken }))
  //     .unwrap()
  //     .then(() => {});
  // };
  const { cartItemsData } = useAppSelector((state) => state.cart);
  const ResultTemplateFunction = (result: Result): JSX.Element => {
    return (
      <PluginLandingPageTemplate
        result={result}
        currencyIsoCode={props?.currencyIsoCode}
        currencySymbol={props?.currencySymbol}
        addToCartHandler={addToCartHandler}
        addToCartButtonLabel={t('ADD_TO_CART')}
        removeFromCartButtonLabel={t('IN_CART')}
        cart={cartItemsData}
      />
    );
  };

  return (
    <>
      <CoveoScripts />
      <div className="listing-interface"></div>
      {hydrate && <style>{baseSearchResultStyles}</style>}

      {hydrate && props.searchEngine && (
        <AtomicSearchInterface
          engine={props.searchEngine}
          fieldsToInclude='["z95xtemplate",
            "ec_category",
            "searchdescription",
            "searchtitle",
            "z95xtemplatename",
            "documenttype",
            "language",
            "productcategory",
            "searchimageurl",
            "productid"
            ]'
          languageAssetsPath="/searchlabels"
          analytics={true}
          language={navigationState.currentLanguage}
        >
          <AtomicSearchLayout class="search-layout mt-10">
            <AtomicLayoutSection section="search"></AtomicLayoutSection>

            <AtomicLayoutSection
              section="facets"
              className={`facets facets-layout-wrapper ${showFacet ? 'is-open' : ''}`}
            >
              <div className="facets-container-backdrop" onClick={handleToggleFacets}></div>

              <section className="facets-container">
                <header className="facets-header">
                  <h4 className="facets-header-title">Refine By:</h4>

                  <button className="facets-header-close" onClick={handleToggleFacets}>
                    <i className="facets-header-close-icon">
                      <img
                        src="https://cdn.avid.com/avidcom/images/shared/icons/search-close.svg"
                        alt=""
                      />
                    </i>
                  </button>
                </header>

                <div className="facet-container refineByContainer">
                  {props.leftRailTopContent}

                  <div className={`${props.hideFacets ? 'hidden' : ''}`}>
                    <AtomicFacetManager className="facet-manager">
                      <AtomicCategoryFacet
                        field="productcategory"
                        label={t('PRODUCT_CATEGORY')}
                        withSearch={false}
                        class="refineByCategory"
                        delimitingCharacter="|"
                        isCollapsed={false}
                        numberOfValues={maxFacetCount}
                        facetId="productcategory"
                      ></AtomicCategoryFacet>
                    </AtomicFacetManager>
                  </div>
                </div>
              </section>
              <section>{props.leftRailBottomConntent}</section>
            </AtomicLayoutSection>

            <AtomicLayoutSection section="main" class="search-main">
              <AtomicSearchBox class="search-box mb-6" suggestionDelay={700}>
                <AtomicSearchBoxQuerySuggestions />
              </AtomicSearchBox>

              <AtomicLayoutSection section="status" class="results-details">
                <section className="results-count">
                  <AtomicQuerySummary />
                </section>

                <section className="results-manipulation">
                  <AtomicResultsPerPage choicesDisplayed="15,30,60,90" class="results-per-page" />

                  <AtomicSortDropdown class="results-sort-dropdown">
                    <AtomicSortExpression label="relevance" expression="relevancy" />
                    <AtomicSortExpression label="Date (older first)" expression="date ascending" />
                    <AtomicSortExpression label="Date (newer first)" expression="date descending" />
                  </AtomicSortDropdown>
                </section>

                <section className="refine-results-button-wrapper">
                  <button onClick={handleToggleFacets} className="refine-results-button">
                    <i className="refine-results-button-icon">
                      <img
                        src="https://cdn.avid.com/avidcom/images/shared/icons/search-refine-icon.svg"
                        alt=""
                      />
                    </i>

                    <span className="refine-results-button-text">Refine Results</span>
                  </button>
                </section>

                <AtomicDidYouMean />
              </AtomicLayoutSection>
              <AtomicLayoutSection
                section="results"
                class="search-results block mb-[50px] change-layout"
              >
                <AtomicResultList
                  template={(result: Result): JSX.Element => ResultTemplateFunction(result)}
                  imageSize="small"
                  display="grid"
                />
                <QueryError />
                <NoResults enableCancelLastAction={false}></NoResults>
              </AtomicLayoutSection>
              <AtomicLayoutSection section="pagination">
                <AtomicPager className="search-pagination pb-20" />
              </AtomicLayoutSection>
            </AtomicLayoutSection>
          </AtomicSearchLayout>
        </AtomicSearchInterface>
      )}
    </>
  );
};

export default PluginSearchResults;
