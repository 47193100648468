import { Field, LinkField } from '@sitecore-jss/sitecore-jss-nextjs';
import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { getCountries } from 'lib/commerce/countries/countries-slice';
import { useCartData } from 'components/cart/cart-hooks';
import { setCheckoutStage } from 'components/checkout/checkout-slice';
import Spinner from 'components/spinner/Spinner';
import MarketplaceCheckoutSummary from '../../components/checkout/marketplace/MarketplaceCheckoutSummary';
import { useSession } from 'next-auth/react';
import { getBearerToken } from 'lib/authentication/account-provider';
import AlertBox from 'components/alert/AlertBox';
import { useI18n } from 'next-localization';
import MarketplaceBillingAddress from 'components/checkout/marketplace/MarketplaceBillingAddress';

export type MarketplaceCheckoutProps = {
  fields: {
    title: Field<string>;
    eula: Field<string>;
    thirdPartyTerms: Field<string>;
    continueShoppingLink: LinkField;
    link: LinkField;
    orderProcessingLink: LinkField;
  };
};

const MarketplaceCheckout = (props: MarketplaceCheckoutProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const { t } = useI18n();
  const checkout = useAppSelector((state) => state.checkout);
  const { billingAddress, checkoutStage } = checkout;
  const cart = useAppSelector((state) => state.cart);
  const cartItemData = useCartData();
  const dataExists =
    cart.cartItemsDataStatus === 'succeeded' && cart.cartItemsDataStatus === 'succeeded';

  const { data: session, status } = useSession();
  const bearerToken = useMemo(() => getBearerToken(session), [session]);

  const [hasWarning, setHasWarning] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  useEffect(() => {
    if (status === 'loading') {
      return;
    }

    dispatch(getCountries());
  }, [dispatch, bearerToken, status]);

  useEffect(() => {
    if (!dataExists || status === 'loading' || billingAddress !== null) {
      return;
    }

    dispatch(setCheckoutStage('payment-billing'));
  }, [dataExists, dispatch, bearerToken, status, billingAddress]);

  useEffect(() => {
    if (!dataExists || status === 'loading' || billingAddress == null) {
      return;
    }

    dispatch(setCheckoutStage('mp-checkout-summary'));
  }, [billingAddress, dataExists, dispatch, status]);

  useEffect(() => {
    if (!hasWarning) {
      return;
    }
    if (alertMessage !== t('Payment_Method_Disallowed')) {
      return;
    }
    if (cartItemData?.containsRecurringPlans) {
      return;
    }
    setHasWarning(false);
    setAlertMessage('');
  }, [alertMessage, cartItemData?.containsRecurringPlans, hasWarning, t]);

  return (
    <div id="checkout--marketplace" className={`${!dataExists ? 'h-96' : ''} mt-4 text-sm`}>
      <div className={`${hasWarning ? '' : 'hidden'} mb-4`}>
        <AlertBox
          containerClass="bg-warning-light border-warning text-warning [&>div>.dismisable-icon]:text-almost-black p-4 flex w-fit justify-center justify-self-center items-baseline md:items-center border rounded space-x-2 col-span-12 lg:col-span-8 lg:col-start-3"
          alertMessage={alertMessage}
          alertType="warning"
          isDismissible={false}
          showAlertBox={true}
          displayIcon={true}
        />
      </div>
      {!dataExists && <Spinner color="#20BBD0" />}
      {dataExists && (
        <div className="relative m-auto after:clear-both">
          {checkoutStage === 'mp-checkout-summary' && (
            <MarketplaceCheckoutSummary
              cart={cart}
              cartItemData={cartItemData}
              continueShoppingLink={props.fields.continueShoppingLink}
              checkout={checkout}
              eula={props.fields.eula}
              thirdPartyTerms={props.fields.thirdPartyTerms}
              orderProcessingLink={props.fields.orderProcessingLink}
              link={props.fields.link}
            />
          )}
          {checkoutStage === 'payment-billing' && <MarketplaceBillingAddress />}
        </div>
      )}
    </div>
  );
};
export default MarketplaceCheckout;
