import { useContext, useEffect, useState } from 'react';
import { Placeholder, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import CoveoSearchEngineContext from 'lib/coveo/coveo-context';
import { setCoveoQuery } from 'components/search/coveo-query-slice';
import { SearchEngine } from '@coveo/headless';
import dynamic from 'next/dynamic';
import { ReferenceStringItem } from 'components/foundation/common/reference-string/reference-string-props';
import { PriceQueue } from 'lib/commerce/product/price-queue';

const BaseSearchResults = dynamic(() => import('../../components/search/BaseSearchResults'));

type QueryCustomSearchProps = ComponentProps & {
  fields: {
    coveoQuery: ReferenceStringItem;
    labels: { value: string };
  };
};

const Default = (props: QueryCustomSearchProps): JSX.Element => {
  const coveoQuery = props.fields?.coveoQuery?.fields?.value?.value;
  const searchEngine = useContext(CoveoSearchEngineContext) as unknown as SearchEngine;
  const dispatch = useAppDispatch();
  const itemListName = 'Custom Search Results';
  const itemListId = `CustomSearchResults_0`;
  const searchHubName = 'AvidComListingSearch';

  const currencyIsoCode = useAppSelector((state) => state.cart.currencyIsoCode);
  const [renderIt, setRenderIt] = useState(false);
  const [renderedCurrencyIsoCode, setRenderedCurrencyIsoCode] = useState<string | null>(null);
  const [priceQueue, setPriceQueue] = useState<PriceQueue | null>(null);

  useEffect(() => {
    // we need it here in order to re-render search results with a new currency
    // otherwise it stucks with the one it was rendered initially
    if (currencyIsoCode === null || currencyIsoCode === renderedCurrencyIsoCode) {
      return;
    }
    setRenderIt(false);
    setRenderedCurrencyIsoCode(currencyIsoCode);
    setTimeout(() => {
      setPriceQueue(new PriceQueue());
      setRenderIt(true);
    }, 100);
  }, [currencyIsoCode, renderIt, renderedCurrencyIsoCode]);

  useEffect(() => {
    dispatch(setCoveoQuery({ query: coveoQuery, searchHub: searchHubName }));

    return () => {
      dispatch(setCoveoQuery({ query: null, searchHub: null }));
    };
  }, [coveoQuery, dispatch]);

  return (
    <>
      {searchEngine && renderIt && currencyIsoCode && (
        <BaseSearchResults
          listId={itemListId}
          listName={itemListName}
          searchEngine={searchEngine}
          labels={props.fields?.labels ?? { value: '' }}
          leftRailBottomConntent={
            <Placeholder name="custom-search-rail-bottom" rendering={props.rendering} />
          }
          currencyIsoCode={currencyIsoCode}
          priceQueue={priceQueue}
        />
      )}
    </>
  );
};

export default withDatasourceCheck()(Default);
