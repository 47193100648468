import { Field, withDatasourceCheck } from '@sitecore-jss/sitecore-jss-nextjs';
import { ComponentProps } from 'lib/component-props';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'lib/store/hooks';
import { getCountries } from 'lib/commerce/countries/countries-slice';
import { setCheckoutStage } from 'components/checkout/checkout-slice';

import dynamic from 'next/dynamic';
const PaymentCollection = dynamic(() => import('components/checkout/shop/PaymentCollection'));

type PaymentProps = ComponentProps & {
  fields: {
    title: Field<string>;
    labels: PaymentLabels;
  } & PaymentLabels;
};

export type PaymentLabels = {
  billingTitle: Field<string>;
  billingFirstName: Field<string>;
  billingLastName: Field<string>;
  billingAddressLine1: Field<string>;
  billingAddressLine2: Field<string>;
  billingCity: Field<string>;
  billingCountry: Field<string>;
  billingState: Field<string>;
  billingPostalCode: Field<string>;
  billingPhoneNumber: Field<string>;
  billingPhoneDisclaimer: Field<string>;
  billingContinue: Field<string>;
  billingCancel: Field<string>;
  agreeToEulaTerms: Field<string>;
};

const Payment = (props: PaymentProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const checkout = useAppSelector((state) => state.checkout);
  const paymentMethods = checkout.paymentMethods;
  const paymentMethodStatus = checkout.paymentMethodStatus;
  const selectedSource = checkout.selectedSource;
  props.fields.labels = { ...props.fields };

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  useEffect(() => {
    const checkPaymentMethods = async (): Promise<void> => {
      if (paymentMethodStatus === 'succeeded' && selectedSource === null) {
        if (paymentMethods.length > 0) {
          dispatch(setCheckoutStage('payment-method'));
        } else {
          dispatch(setCheckoutStage('payment-billing'));
        }
      }
    };

    checkPaymentMethods();
  }, [dispatch, paymentMethodStatus, paymentMethods, selectedSource]);

  return (
    <>
      <div>
        <PaymentCollection fields={props.fields} />
      </div>
    </>
  );
};

export default withDatasourceCheck()<PaymentProps>(Payment);
