import { searchBoxStyles } from '../components/global-navigation/marketplace/search-styles';
import { pushCoveoSearchEvent } from 'lib/google-analytics/coveo-site-search';
import {
  Field,
  LinkField,
  withDatasourceCheck,
  isEditorActive,
  ImageField,
  ImageFieldValue,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { useRouter } from 'next/router';
import { default as NextLink } from 'components/foundation/non-sitecore/NextLink';
import { default as NextjsLink } from 'next/link';
import { ComponentProps } from 'lib/component-props';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'lib/store/store';
import React, { useEffect, useState } from 'react';
import { getCartSummary } from 'components/cart/cart-slice';
// import NextImage from 'components/foundation/non-sitecore/NextImage';
// import {
//   avidLogoWhite,
//   shoppingCartWhite,
// } from 'components/global-navigation/marketplace/header-icons';
import { TAILWIND, useScreenSize } from 'lib/hooks/use-screen-size';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { getBearerToken } from 'lib/authentication/account-provider';
import { useSession } from 'next-auth/react';
import dynamic from 'next/dynamic';
import { SearchEngine } from '@coveo/headless';
import { AvidCdnImage } from 'components/foundation/non-sitecore/AvidCdnImage';

const AtomicSearchInterface = dynamic(
  () => import('@coveo/atomic-react').then((mod) => mod.AtomicSearchInterface),
  { ssr: false }
);

const AtomicSearchBox = dynamic(
  () => import('@coveo/atomic-react').then((mod) => mod.AtomicSearchBox),
  { ssr: false }
);

const AtomicExternal = dynamic(
  () => import('@coveo/atomic-react').then((mod) => mod.AtomicExternal),
  { ssr: false }
);

type MarketplaceHeaderProps = ComponentProps & {
  fields: {
    headerLink: LinkField;
    searchText: Field<string>;
    searchToolTip: Field<string>;
  };
};

const Header = (props: MarketplaceHeaderProps): JSX.Element => {
  const [hydrate, setHydrate] = useState(false);
  const screenSize = useScreenSize();
  const isMobileView = screenSize.width < TAILWIND.sm;
  const [focusSearchBox, setFocusSearchBox] = useState(false);
  const editorActive = isEditorActive();
  const dispatch = useDispatch<AppDispatch>();
  const router = useRouter();
  const cart = useSelector((state: RootState) => state.cart);
  const totalCount =
    cart?.cartSummaryData?.totalProductCount && cart.cartSummaryData.totalProductCount;
  const cartValue: ImageFieldValue = {
    // src: shoppingCartWhite,
    src: 'https://cdn.avid.com/avidcom/images/shared/icons/cart-icon-white.png',
    height: '30',
    width: '30',
    alt: 'cart icon',
  };
  const cartImage: ImageField = { value: cartValue };
  const logoValue: ImageFieldValue = {
    // src: avidLogoWhite,
    src: 'https://cdn.avid.com/avidcom/images/shared/avid-logo-white.png',
    height: '18',
    width: '64',
    alt: 'logo image',
  };
  const logoImage: ImageField = { value: logoValue };
  const isSearchPage = router.asPath.includes('/marketplace/search');
  const goToCart = (): void => {
    router.push(`/marketplace/cart`);
  };

  const { data: session, status } = useSession();
  const [searchEngine, setSearchEngine] = useState<SearchEngine<object> | undefined>(undefined);

  const organizationId =
    process.env.COVEO_ORGANIZATION && process.env.COVEO_ORGANIZATION.length > 0
      ? process.env.COVEO_ORGANIZATION
      : '';

  useEffect(() => {
    if (status === 'loading') {
      return;
    }

    const bearerToken = getBearerToken(session);
    setHydrate(true);
    dispatch(getCartSummary({ bearerToken: bearerToken }));
  }, [dispatch, session, status]);

  useEffect(() => {
    if (isSearchPage) {
      return;
    }
    const initializeSearchEngine = async (): Promise<void> => {
      const coveo = await import('@coveo/atomic-react');
      const engine = coveo.buildSearchEngine({
        configuration: {
          accessToken:
            process.env.COVEO_ACCESS_TOKEN && process.env.COVEO_ACCESS_TOKEN.length > 0
              ? process.env.COVEO_ACCESS_TOKEN
              : '',
          organizationId,
          organizationEndpoints: coveo.getOrganizationEndpoints(organizationId),
          search: {
            pipeline:
              process.env.COVEO_PIPELINE_MARKETPLACE &&
              process.env.COVEO_PIPELINE_MARKETPLACE.length > 0
                ? process.env.COVEO_PIPELINE_MARKETPLACE
                : '',
          },
          analytics: {
            analyticsClientMiddleware: (...[, payload]) => {
              pushCoveoSearchEvent(payload);
              return payload;
            },
          },
        },
      });
      // we rewrite executeFirstSearch method here in order to avoid un-needed initial calls
      // to coveo API each time when any page, except search page, loading
      engine.executeFirstSearch = (): void => {};

      setSearchEngine(engine);
    };

    initializeSearchEngine();
  }, [isSearchPage, organizationId]);

  return (
    <div className="w-full">
      <header className="bg-mp-background-general fixed top-0 left-1/2 -translate-x-1/2 z-10 w-full px-2.5 md:px-6 m-auto max-w-[1480px] py-5 flex flex-row justify-between">
        {(props?.fields?.headerLink?.value || editorActive) && (
          <span
            className={`${
              focusSearchBox
                ? 'hidden'
                : 'text-mp-txt-bright m-0 font-semiboldpl-2 !leading-10 text-base md:text-2xl lg:text-2xl hover:transition-shadow hover:duration-500 hover:shadow-mp_underline shadow-grayishblue'
            }`}
          >
            <NextjsLink className="" href="/marketplace">
              {/* <NextImage
                field={logoImage}
                className="hidden sm:inline-block align-middle w-16 pb-1 cursor-pointer"
              /> */}
              <AvidCdnImage
                field={logoImage}
                className="hidden sm:inline-block align-middle w-16 pb-1 cursor-pointer"
              />
            </NextjsLink>
            <span className="hidden sm:inline-block px-2">|</span>
            <NextLink className="inline-block font-bold" field={props.fields.headerLink} />
          </span>
        )}
        <div
          className={`${
            focusSearchBox ? 'w-full' : 'w-1/2'
          } marketplace-search-page relative flex justify-center items-center xl:w-2/3`}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={`${
              focusSearchBox
                ? 'inline-block text-white h-6 w-6 p-px mr-2.5 cursor-pointer'
                : 'hidden'
            }`}
            onClick={(): void => setFocusSearchBox(false)}
          />
          {hydrate && !isSearchPage && searchEngine && (
            <div className="w-full">
              <AtomicSearchInterface
                languageAssetsPath="/searchlabels"
                localization-compatibility-version="v4"
                engine={searchEngine}
                analytics={true}
              >
                <AtomicSearchBox
                  onFocus={(): void => {
                    setFocusSearchBox(isMobileView);
                  }}
                  onBlur={(): void => {
                    setFocusSearchBox(false);
                  }}
                  redirection-url="/marketplace/search"
                  class="h-40px"
                  numberOfQueries={0}
                  suggestionDelay={60000}
                ></AtomicSearchBox>
              </AtomicSearchInterface>
            </div>
          )}
          {hydrate && isSearchPage && (
            <AtomicExternal className="w-full">
              <AtomicSearchBox
                onFocus={(): void => {
                  setFocusSearchBox(isMobileView);
                }}
                onBlur={(): void => {
                  setFocusSearchBox(false);
                }}
                class="h-40px"
                numberOfQueries={0}
                suggestionDelay={60000}
              ></AtomicSearchBox>
            </AtomicExternal>
          )}
        </div>
        <style>{searchBoxStyles}</style>
        <div
          className={`${
            focusSearchBox ? 'hidden' : 'inline-block relative cursor-pointer w-7 h-7'
          }`}
          onClick={(): void => goToCart()}
        >
          {/* <NextImage field={cartImage} className="absolute block align-middle w-7 h-7 top-3" /> */}
          <AvidCdnImage field={cartImage} className="absolute block align-middle w-7 h-7 top-3" />
          <span className="relative block top-o text-center text-sm text-mp-txt-bright">
            {totalCount}
          </span>
        </div>
      </header>
    </div>
  );
};

export default withDatasourceCheck()<MarketplaceHeaderProps>(Header);
