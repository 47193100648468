export const baseSearchResultStyles = `
  atomic-search-layout {
    margin-top: 80px;
  }

  @media (max-width: 1023px) {
    atomic-search-layout {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }

  @media (min-width: 1024px) {
    atomic-search-layout {
      grid-template-areas:
        '. . atomic-section-search .'
        '. atomic-section-facets atomic-section-main .'!important;
    }
  }

  .results-details {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 40px;
  }

  @media (min-width: 600px) {
    .results-details {
      align-items: center;
      flex-direction: row;
    }
  }

  .results-count {
    align-items: center;
    display: flex;
    flex: 0 0 100%;
    margin: 0!important;
  }

  @media (min-width: 1275px) {
    .results-count {
      flex: 1 1 100%;
    }
  }

  .results-manipulation {
    align-items: center;
    display: flex;
    flex: 1 1 100%;
    flex-direction: column;
    gap: 20px;
    justify-content: space-between;
  }

  @media (min-width: 600px) {
    .results-manipulation {
      align-items: flex-start;
      flex-direction: row;
      gap: 40px;
    }
  }

  @media (min-width: 1100px) {
    .results-manipulation {
      align-items: center;
    }
  }

  .results-per-page {
    min-width: 270px;
  }

  .results-sort-dropdown {
    min-width: 235px;
  }

  atomic-pager::part(buttons) {
    gap: 2px;
    justify-content: center;
  }

  atomic-pager::part(next-button),
  atomic-pager::part(previous-button) {
    border-color: transparent;
    color: #222;
    line-height: 0;
  }

  atomic-pager::part(page-button) {
    color: #222;
    font-size: 16px;
    line-height: calc(24 / 16 * 1em);
    transition-property: border-color, color, background-color;
    transition-duration: .3s;
  }

  atomic-pager::part(page-button active-page-button) {
    background-color: var(--atomic-pill-text);
    border-color: var(--atomic-pill-text);
    color: #fff;
  }

  atomic-query-summary {
    font-size: 16px;
    line-height: calc(24 / 16 * 1em);
    text-align: center;
  }

  @media (min-width: 1100px) {
    atomic-query-summary {
      text-align: left;
    }
  }

  atomic-results-per-page,
  atomic-sort-dropdown {
    font-size: 16px;
    line-height: calc(24 / 16 * 1em);
  }

  atomic-results-per-page::part(label),
  atomic-sort-dropdown::part(label) {
    font-size: 16px;
  }

  atomic-results-per-page::part(label) {
    font-weight: 700;
  }

  atomic-sort-dropdown::part(label) {
    line-height: calc(24 / 16 * 1em);
    margin-left: 0;
  }

  atomic-sort-dropdown::part(select) {
    font-size: 16px;
    line-height: calc(24 / 16 * 1em);
    padding-right: 3rem;
  }

  atomic-results-per-page::part(buttons) {
    gap: 2px;
  }

  atomic-results-per-page::part(button) {
    color: var(--atomic-pill-text);
    font-size: 16px;
    height: 2rem;
    line-height: calc(24 / 16 * 1em);
    width: 2rem;
  }

  atomic-results-per-page::part(active-button) {
    border-color: transparent;
    color: var(--atomic-on-background);
    font-weight: 700;
  }

  atomic-search-box::part(wrapper) {
    border-radius: 4px;
  }

  atomic-search-box::part(input) {
    font-size: 24px;
    line-height: calc(36 / 24 * 1em);
  }

  atomic-search-box::part(clear-button) {
    aspect-ratio: 1 / 1;
    line-height: 0;
    position: relative;
  }

  atomic-search-box::part(clear-icon) {
    height: 16px;
    width: 16px;
  }

  atomic-search-box::part(submit-button) {
    aspect-ratio: 1 / 1;
    background-color: #194BF5;
    position: relative;
    width: 3.63rem;
  }

  atomic-search-box::part(submit-icon) {
    height: 24px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 24px;
  }

  atomic-search-box::part(suggestions-wrapper) {
    border-radius: 0;
    box-shadow: 0px 4px 16px 4px rgba(34, 34, 34, 0.16);
    margin-top: 8px;
  }

  atomic-search-box::part(query-suggestion-icon) {
    display: none;
  }

  atomic-search-box::part(query-suggestion-text) {
    font-size: 16px;
    line-height: calc(24 / 16 * 1em);
  }

  atomic-layout-section.facets {
  }

  atomic-search-layout atomic-layout-section[section='facets'] {
    display: block!important;
  }

  atomic-search-layout atomic-layout-section[section='facets'] * {
    margin-bottom: 0;
  }

  atomic-search-layout atomic-layout-section[section='status'] {
    display: flex;
  }

  .tab-container {
    padding-bottom: 32px;
  }

  atomic-custom-tab {}

  atomic-custom-tab::part(tab-anchor) {
    box-sizing: border-box;
    border: 0;
    display: flex;
    color: var(--atomic-on-background);
    font-size: 16px;
    font-weight: 400;
    height: auto;
    line-height: calc(24 / 16 * 1em);
    margin: 0;
    padding: 0 .5rem 0 0;
    width: 100%;
  }

  atomic-custom-tab::part(tab-icon) {
    line-height: 0;
  }



  atomic-custom-tab::part(tab-label) {}

  .category-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .category-list-item {
    display: block;
  }

  .facet-manager {
    display: flex;
    padding-bottom: 15px;
    flex-direction: column;
    gap: 32px;
  }

  .facet-container {
    border: 1px solid var(--atomic-neutral);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .facets-container {
       position: relative;
    bottom: 28px;
  }
  atomic-facet::part(facet),
  atomic-numeric-facet::part(facet),
  atomic-category-facet::part(facet) {
    border: 0;
    padding-bottom: 0;
    padding-top: 0;
  } 

  .refineByCategory::part(facet)
  {
  padding-top:16px;
  }

  atomic-facet::part(label-button),
  atomic-numeric-facet::part(label-button),
  atomic-category-facet::part(label-button),
  .category-title {
    border-radius: 2px;
    background-color: #F6F7F7;
    padding: 8px;
  }

  atomic-facet::part(value-checkbox),
  atomic-numeric-facet::part(value-checkbox),
  atomic-category-facet::part(value-checkbox) {
    border: 2px solid black;
    height: 18px;
    width: 18px;
  }

  atomic-facet::part(values),
  atomic-numeric-facet::part(values),
  atomic-category-facet::part(values) {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  atomic-facet::part(value-checkbox-label),
  atomic-numeric-facet::part(value-checkbox-label),
  atomic-category-facet::part(value-checkbox-label) {
    display: flex;
    gap: 8px;
    padding: 0px 0.5rem 0px 2rem;
  }

  atomic-facet::part(show-more),
  atomic-numeric-facet::part(show-more),
  atomic-category-facet::part(show-more) {
    padding-bottom: 0;
  }

  atomic-category-facet::part(value-link) {
    display: flex;
    gap: 8px;
    padding: 0px 0.5rem;
  }

  atomic-facet::part(value-checkbox-label) {
    display: flex;
    justify-content: space-between;
  }

  atomic-facet::part(value-label) {
    font-size: 16px;
    font-weight: 400;
    line-height: calc(24 / 16 * 1em);
  }

  atomic-facet::part(value-count) {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .28px;
    line-height: normal;
  }

  .refine-results-button-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  @media (min-width: 1024px) {
    .refine-results-button-wrapper {
      display: none;
    }
  }

  .refine-results-button {
    align-items: center;
    border: 2px solid #343434;
    border-radius: 4px;
    display: flex;
    gap: 8px;
    padding: 10px 16px 10px 8px;
  }

  .refine-results-button-icon {}

  .refine-results-button-text {
    font-size: 18px;
    font-weight: 700;
    line-height: calc(27 / 18 * 1em);
  }

  .facets-header {
    margin-bottom: 12px;
  }

  .facets-header-title {}

  .facets-header-close {
    display: none;
  }

  .facets-header-close-icon {}

  .facets-header-close-icon > svg {}

  @media (max-width: 1023px) {
    .facets-layout-wrapper {
      overflow: hidden;
    }

    .facets-container-backdrop {
      background-color: #000;
      bottom: 0;
      opacity: 0;
      position: fixed;
      right: 0;
      top: 0;
      transition: opacity .3s ease;
      width: 100%;
      z-index: -100;
    }

    .facets-layout-wrapper.is-open .facets-container-backdrop {
      opacity: .75;
      z-index: 990;
    }

    .facets-container {
      background-color: #fff;
      bottom: 0;
      max-width: 375px;
      overflow: auto;
      position: fixed;
      right: 0;
      top: 0;
      transition: width .3s ease;
      width: 0%;
      z-index: 999;
    }

    .facets-layout-wrapper.is-open .facets-container {
      width: 90%;
    }

    .facets-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 0;
      padding: 16px 14px 16px 20px;
    }

    .facets-header-close {
      display: block;
    }        
  }
    @media (max-width: 575px), (min-width: 576px) { 
      atomic-search-interface
      atomic-search-layout
      atomic-layout-section[section='main']
      atomic-layout-section[section='results']
      atomic-result-list::part(result-list-grid-clickable-container) {      
        max-width: 400px;
        margin: 0 auto;
        border: 1px solid rgb(209, 213, 215);
        border-radius: 4px;
      } 
    }
    @media (min-width: 576px) {  
        atomic-search-interface
        atomic-search-layout
        atomic-layout-section[section='main']
        atomic-layout-section[section='results']
        atomic-result-list[display='grid']::part(result-list) {
          grid-template-columns: repeat(2, 1fr) !important;
          margin: 0 auto;
          grid-row-gap: 2rem;
          grid-column-gap: 2rem;        
      }       
    }      
    @media (min-width: 1200px) {  
        atomic-search-interface
        atomic-search-layout
        atomic-layout-section[section='main']
        atomic-layout-section[section='results']      
        atomic-result-list[display='grid']::part(result-list) {
          grid-template-columns: repeat(3, 1fr) !important;
          grid-row-gap: 2rem;
          grid-column-gap: 2rem;
      }       
    }
    @media (min-width: 1024px) {  
        atomic-search-interface
        atomic-search-layout
        atomic-layout-section[section='main']
        atomic-layout-section[section='results']
        atomic-result-list::part(result-list-grid-clickable-container) {
            border: 1px solid rgb(209, 213, 215);
            border-radius: 4px;
        }       
    }
    @media (max-width: 575px) {  
        atomic-search-interface
        atomic-search-layout
        atomic-layout-section[section='main']
        atomic-layout-section[section='results']
        atomic-result-list[display='grid']::part(result-list) {
          grid-template-columns: repeat(1, 1fr) !important;
          margin: 0 auto;
          grid-row-gap: 2rem;
          grid-column-gap: 2rem;        
      }       
    }                            
`;
