import { Field, Text } from '@sitecore-jss/sitecore-jss-nextjs';
import { isEditorActive } from '@sitecore-jss/sitecore-jss-nextjs/utils';
import { ComponentProps } from 'lib/component-props';
import React from 'react';

type BlankSpaceProps = ComponentProps & {
  fields: {
    height: Field<string>;
  };
};

const BlankSpace = (props: BlankSpaceProps): JSX.Element => {
  const editorActive = isEditorActive();
  const heightVal = props?.fields?.height?.value;

  return editorActive ? (
    <div>
      <div className="max-w-1208 text-left mx-auto border-2 border-dashed border-introgray p-2">
        Below is a blank space component with height
        <Text
          field={props.fields?.height}
          tag="div"
          className="overflow-x-hidden relative top-2 inline-block border-2 w-32 ml-1 px-1"
        />
        px.
        <div className="text-12">This content wont be visible in live page.</div>
      </div>
      <div
        style={{ height: heightVal ? heightVal + 'px' : 0 + 'px' }}
        className={`   text-left  text-20 border-1 bg-introgray opacity-5`}
      ></div>
    </div>
  ) : (
    <div style={{ height: heightVal ? heightVal + 'px' : 0 + 'px' }}></div>
  );
};

export default BlankSpace;
